import React from 'react';

import classes from './Close.module.css';
import PropTypes from "prop-types";

const Close = (props) => {
    return (
        <button onClick={props.closeHandler} className={classes.closeButton}>✕</button>
    );
};

Close.displayName = "Close";

Close.propTypes = {
    closeHandler: PropTypes.func,
}

export default Close;