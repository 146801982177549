import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

const Protected = (props) => {
    const navigate = useNavigate()
    const { Component } = props
    useEffect(() => {
        let rolesList = sessionStorage.getItem('rolesList')
        if (rolesList !== null && rolesList.includes('ROLE_SYSTEM')) {
            navigate('/admin')
        } else {
            navigate('/')
        }
    }, [])

    return (
        <div>
            <Component />
        </div>
    )
}

Protected.propTypes = {
    Component: PropTypes.func,
}

export default Protected
