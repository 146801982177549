import React from 'react'

const HexContext = React.createContext({
    selectMode: false,
    copyMode: false,
    changed: false,
    copyData: {},
    selectData: [],
    selectedHexagons: [],
    activeHex: false,
    addHexMode: false,
    colorPicker: false,
    playBackMode: false,
    showTimer: false,
    boardPopupProps: {},
    focusedHex: null,
    setBoardPopupProps: () => {},
    enableSelectMode: () => {},
    setCopyData: () => {},
    enableCopyMode: () => {},
    disableModes: () => {},
    disableCopyMode: () => {},
    enableHexItem: () => {},
    enableZoom: () => {},
    setSelectData: () => {},
    addHexModeHandler: () => {},
    colorPickerHandler: () => {},
    enablePlayBackMode: () => {},
    disablePlayBackMode: () => {},
    showTimerHandler: () => {},
    removeSelected: () => {},
    setSelected: () => {},
    clearSelected: () => {},
    setChanged: () => {},
    setFocusedHex: () => {},
    reset: () => {},
})

export default HexContext
