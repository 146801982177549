import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Button } from 'semantic-ui-react'
import classes from './LoginScreen.module.css'
import Hexagon from '../Hexagon/Hexagon'
import AuthContext from '../../../store/auth/auth-context'
import LinkVideos from '../LinkVideos/LinkVideos';
import useDetectKeyboardOpen from "use-detect-keyboard-open";



const LoginScreen = () => {
    const ctx = useContext(AuthContext)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const [responseStatus, setResponseStatus] = useState('')
    const isKeyboardOpen = useDetectKeyboardOpen();
    const [loading, setLoading] = useState(false)
    const onSubmit = async (data) => {
        setLoading(true)
        await fetch(process.env.REACT_APP_API_URL + '/user/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response.status === true) {
                    setResponseStatus(response.message)
                    setLoading(false)
                    ctx.onLogin(data.userName, response.token, response.newUser, response.rolesList)
                } else {
                    setResponseStatus(response.message)
                    setLoading(false)
                }
            })
    }

    const setResetHandler = () => {
        ctx.stageHandler('reset')
    }
    return (
        <div className={classes.login}>
            {loading && (
                <div className={classes.popup}>
                    <div className={classes.overlay} />
                    <div className={classes.popupMessage}>Loading...</div>
                </div>
            )}
            <h4 className="text-center">{responseStatus}</h4>
            <div className="container">
                {ctx.guestMode && (
                    <h2 className="text-center">
                        Please Login before save or use multiple boards
                    </h2>
                )}
                <div className="row justify-center">
                    <div className="col-6">
                        <Hexagon>
                            <div className={classes.login}>
                                <h2 className={classes.guestTitle}>Log in</h2>
                                <Form
                                    className={classes.form}
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <Form.Field className={classes.control}>
                                        <input
                                            placeholder="Email"
                                            type="text"
                                            autoComplete="off"
                                            {...register('userName', {
                                                required: true,
                                            })}
                                        />
                                    </Form.Field>
                                    {errors.userName && (
                                        <p className={classes.message}>
                                            Please check the Name
                                        </p>
                                    )}
                                    <Form.Field className={classes.control}>
                                        <input
                                            placeholder="Password"
                                            type="password"
                                            autoComplete="off"
                                            {...register('password', {
                                                required: true,
                                            })}
                                        />
                                    </Form.Field>
                                    {errors.password && (
                                        <p className={classes.message}>
                                            Please check the Password
                                        </p>
                                    )}
                                    <Button
                                        className={classes.btn}
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Form>
                                <span
                                    className={classes.forgot}
                                    onClick={setResetHandler}
                                >
                                    Forgot your password? Or need to reset?
                                </span>
                            </div>
                        </Hexagon>
                    </div>
                    {/*<div className="col-6">*/}
                    {/*  <Guest />*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className={` ${isKeyboardOpen ? `${classes.linkTitleKeyboard}` : `${classes.linkTitle}`}`} >
                <LinkVideos />
            </div>
            
        </div>
    )
}

export default LoginScreen
