import React, { useContext, useEffect, useState } from 'react'

import classes from './Timer.module.css'
import PropTypes from 'prop-types'
import HexContext from '../../../store/hex/hex-context'
import useViewPort from '../../../hooks/useViewPort'


const Timer = (props) => {
    const ctx = useContext(HexContext)
    const [active, setActive] = useState(false)
    const [timer, setTimer] = useState(1)
    const [currentTimer, setCurrentTimer] = useState(1)
    const [mobile] = useViewPort()
    // const [popup, setPopup] = useState(false)

    const stopTimer = () => {
        setActive(false)
        setTimer(1)
    }

    const activeHandler = () => {
        if (ctx.colorPicker || ctx.addHexMode) {
            ctx.disableModes()
        }
        setActive(true)
    }

    const changeTimer = (e) => {
        setTimer(e.target.value)
    }


    const minimizeHandler=()=>{
        ctx.showTimerHandler(!ctx.showTimer)
    }


    useEffect(() => {
        setCurrentTimer(timer)
        if (active && timer > 0) {
            const interval = setInterval(function () {
                props.setPopupProps({
                    message: 'Time is up!',
                    open: true,
                    withClose: true,
                })
                setActive(false)
            }, timer * 60 * 1000)
            return () => clearInterval(interval)
        }
    }, [active, timer, setCurrentTimer])

    useEffect(() => {
        if (active && timer > 0) {
            const interval = setInterval(function () {
                setCurrentTimer(currentTimer - 1)
            }, 60 * 1000)
            return () => clearInterval(interval)
        }
    }, [timer, currentTimer, setCurrentTimer, active])

    return (
        <div className={classes.timer}>
        {mobile && <img src={require('../../../assets/mobile/minimize.png')}  className={classes.minimize} onClick={minimizeHandler}/>}
            <div className={classes.flexWrap}>
                <input
                    min={1}
                    max={60}
                    value={timer}
                    type="number"
                    onChange={changeTimer}
                />
                <div onClick={activeHandler} className={classes.button}>
                    <img
                        draggable="false"
                        className={`${classes.image}`}
                        src={require('../../../assets/Timer_Start.png')}
                    />
                </div>
                <div
                    onClick={stopTimer}
                    className={`${classes.button} ${classes.stop}`}
                >
                    <img
                        draggable="false"
                        className={`${classes.image}`}
                        src={require('../../../assets/Timer_Stop.png')}
                    />
                </div>
            </div>

            <div className={classes.timeLeft}>
                {active && <span>Time Left : {currentTimer} min</span>}
            </div>
        </div>
    )
}

Timer.propTypes = {
    setPopupProps: PropTypes.func,
}

export default React.memo(Timer)
