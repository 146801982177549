import React, { useContext } from 'react'

import PropTypes from 'prop-types'
import HexContext from '../../../../store/hex/hex-context'

const SidebarItem = ({
    clickHandler,
    img,
    id,
    tooltip,
    className,
    active,
    disabled,
}) => {
    const ctx = useContext(HexContext)
    const handleClick = () => {
        if (ctx.playBackMode) {
            ctx.disablePlayBackMode()
        }
        if (
            !ctx.boardPopupProps.active ||
            id === 'canvases' ||
            id === 'log_out' ||
            id === 'menu_canvas'
        ) {
            clickHandler()
        }
    }
    return (
        <div
            className={`sidebar-item-wrapper ${className} ${
                active && 'sidebar-item-wrapper-active'
            }`}
            id={id}
        >
            <div onClick={handleClick} className={`sidebar-item-button`}>
                <img
                 draggable="false"
                    className={`sidebar-item-image  ${
                        disabled && 'disabled-item'
                    }`}
                    src={img}
                />
                <span className={`sidebar-item-tooltip`}>{tooltip}</span>
            </div>
        </div>
    )
}

SidebarItem.propTypes = {
    clickHandler: PropTypes.func,
    tooltip: PropTypes.string,
    id: PropTypes.string,
    img: PropTypes.string,
    className: PropTypes.string,
    active: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default SidebarItem
