import React from 'react'
import classes from './LinkVideos.module.css'

const LinkVideos = () => {
  return (

    <div className={classes.links} >
      <a className={classes.anchor} href=" https://www.thinkfully.co/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
    </div>
  )
}

export default LinkVideos