import React, { useContext } from 'react'

import classes from './ColorItem.module.css'
import HexContext from '../../../../store/hex/hex-context'
import PropTypes from 'prop-types'
import BoardContext from '../../../../store/board/board-context'

const ColorItem = (props) => {
    const ctx = useContext(HexContext)
    const boardCtx = useContext(BoardContext)

    const colorHandler = () => {
        const presentCount = boardCtx.timeline.present.data
        if (ctx.selectMode === true) {
            let prevState = [...presentCount]
            const changedColors = prevState.map((item, index) =>
                ctx.selectedHexagons.includes(index)
                    ? { ...item, color: props.color }
                    : item
            )
            boardCtx.setHexagons({ data: changedColors })
            boardCtx.setChanged(true)
            ctx.enableSelectMode()
        } else if (ctx.activeHex !== false && ctx.activeHex !== undefined) {
            let prevState = [...presentCount]
            let newObject = { ...prevState[ctx.activeHex] }
            newObject.color = props.color
            prevState[ctx.activeHex] = newObject
            boardCtx.setHexagons({ data: prevState })
            boardCtx.setChanged(true)
        }
        ctx.colorPickerHandler(false)
    }

    return (
        <div onClick={colorHandler} className={classes.picker}>
            <div
                style={{ backgroundColor: props.color }}
                className={`${classes.overlay} ${
                    props.color === '#ffffff' && classes.withBorder
                }`}
                id={props.id}
            />
        </div>
    )
}

ColorItem.propTypes = {
    multiColorHandler: PropTypes.func,
    color: PropTypes.string,
    setActive: PropTypes.func,
    id: PropTypes.string,
}

export default ColorItem
