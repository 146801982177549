import React from 'react'

const AuthContext = React.createContext({
    menuToggle: true,
    isLoggedIn: false,
    userName: null,
    token: null,
    boardName: false,
    boardMessage: false,
    stage: 'intro',
    shareId: null,
    shareMode: false,
    guestMode: false,
    restoreMode: false,
    restoreToken: null,
    boardImage: null,
    boardTitle: '',
    versions: [],
    boards: [],
    boardData: {},
    isNewUser: false,
    isonline: true,
    dataLoading: true,
    onLogin: () => {},
    onLogout: () => {},
    menuToggleHandler: () => {},
    shareIdHandler: () => {},
    boardMessageHandler: () => {},
    boardTitleHandler: () => {},
    boardNameHandler: () => {},
    getBoardHandler: () => {},
    getBoardsHandler: () => {},
    guestModeHandler: () => {},
    deleteBoardHandler: () => {},
    restoreModeHandler: () => {},
    getVersionsHandler: () => {},
    setIsNewUser: () => {},
    setIsOnline: () => {},
})

export default AuthContext
