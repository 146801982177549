import React, { useContext } from 'react'

import classes from './Hexagon.module.css'
import AuthContext from '../../../store/auth/auth-context'
import PropTypes from 'prop-types'

const Hexagon = (props) => {
    const authCtx = useContext(AuthContext)
    return (
        <div
            className={`${classes.hexagon} ${classes[props.popup]} ${
                classes[authCtx.menuToggle]
            }`}
        >
            <div className={classes.box}>
                <div className={classes.textcontent}>{props.children}</div>
            </div>
        </div>
    )
}

Hexagon.propTypes = {
    popup: PropTypes.string,
    children: PropTypes.node.isRequired,
}

export default Hexagon
