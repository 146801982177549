import React, { useContext } from 'react'

import classes from './Login.module.css'
import Intro from './Intro/Intro'
import LoginScreen from './LoginScreen/LoginScreen'
import Signup from './Signup/Signup'
import ResetScreen from './ResetScreen/ResetScreen'
import AuthContext from '../../store/auth/auth-context'

const Login = () => {
    const stage = useContext(AuthContext)
    const loginHandler = () => {
        stage.stageHandler('login')
    }

    const signUpHandler = () => {
        stage.stageHandler('signup')
    }

    const boardStageHandler = () => {
        stage.stageHandler('board')
    }

    return (
        <div className={classes.login}>
            <header className={classes.header}>
                <button onClick={loginHandler} className={classes.button}>
                    Log in
                </button>
                <button onClick={signUpHandler} className={classes.button}>
                    Sign up
                </button>
                <a  href="https://www.loom.com/share/29e624189e08482193ec139cb62418c9" target="_blank" rel="noopener noreferrer" className={classes.darkBtn}>
                Intro video
                </a>
                {stage.guestMode && (
                    <button
                        onClick={boardStageHandler}
                        className={classes.button}
                    >
                        Board
                    </button>
                )}
            </header>
            {stage.stage === 'intro' && <Intro />}
            {stage.stage === 'login' && <LoginScreen />}
            {stage.stage === 'signup' && <Signup />}
            {stage.stage === 'reset' && <ResetScreen />}


        </div>
    )
}

export default Login
