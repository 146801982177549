import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Button } from 'semantic-ui-react'
import classes from './Signup.module.css'
import Hexagon from '../Hexagon/Hexagon'
import AuthContext from '../../../store/auth/auth-context'
import RestorePassword from '../RestorePassword/RestorePassword'
import LinkVideos from '../LinkVideos/LinkVideos';
import useDetectKeyboardOpen from "use-detect-keyboard-open";

const Signup = () => {
    const ctx = useContext(AuthContext)
    const isKeyboardOpen = useDetectKeyboardOpen();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const [responseStatus, setResponseStatus] = useState('')
    // const [inviteCode, setInviteCode] = useState('')
    // const [authorizedMessage, setAuthorized] = useState(false)
    const onSubmit = (data) => {
        fetch(process.env.REACT_APP_API_URL + '/user/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response.status) {
                    setResponseStatus(response.message)
                    ctx.stageHandler('login')
                } else {
                    setResponseStatus(response.message)
                }
            })
    }

    const responseStatusHandler = (status) => {
        setResponseStatus(status)
    }

    // const loginHandler = () => {
    //     if (inviteCode === 'Thinkfully') {
    //         ctx.onLogin();
    //     } else {
    //         setAuthorized(true)
    //     }
    //
    // }

    // const inviteCodeHandler = (e) => {
    //     console.log(inviteCode)
    //     setInviteCode(e.target.value)
    // }
    return (
        <div className={classes.signup}>
            <div className="container">
                <h4 className={classes.responseStatus}>{responseStatus}</h4>
                <div className="row">
                    <div className="col-6">
                        <Hexagon>
                            <h2 className={classes.guestTitle}>Sign Up</h2>
                            <Form
                                className={classes.form}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Form.Field className={classes.control}>
                                    <input
                                        placeholder="Invite Code"
                                        type="text"
                                        {...register('inviteCode', {
                                            required: true,
                                            maxLength: 10,
                                        })}
                                    />
                                    {errors.inviteCode && (
                                        <p className={classes.message}>
                                            Incorrect invite code.
                                        </p>
                                    )}
                                </Form.Field>
                                <Form.Field className={classes.control}>
                                    <input
                                        placeholder="Name"
                                        type="text"
                                        {...register('fullName', {
                                            required: 'Name is required.',
                                            maxLength: { value: 200, message: 'Name should be between 1 and 200 characters.' },
                                        })}
                                    />
                                    {errors.fullName && (
                                        <p className={classes.message}>
                                            {errors.fullName.message}
                                        </p>
                                    )}
                                </Form.Field>

                                <Form.Field className={classes.control}>
                                    <input
                                        placeholder="Email"
                                        type="email"
                                        {...register('userName', {
                                            required: 'Email is required.',
                                            pattern:
                                            
                                           { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                             message: 'Please enter a valid email address.',}
                                        })}
                                    />
                                    {errors.userName && (
                                        <p className={classes.message}>
                                            {errors.userName.message}
                                        </p>
                                    )}
                                </Form.Field>

                                <Form.Field className={classes.control}>
                                    <input
                                        placeholder="Password"
                                        type="password"
                                        {...register('password', {
                                            required: true,
                                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@\-_])[A-Za-z\d@\-_]{8,20}$/i,
                                        })}
                                    />
                                    {errors.password && errors.password.type === 'required' && (
                                        <p className={classes.message}>Password is required.</p>
                                    )}
                                    {errors.password && errors.password.type === 'pattern' && (
                                        <p className={classes.message}>
                                            Password should be 8 to 20 characters and must include 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol (from @, -, _) character.
                                        </p>
                                    )}
                                </Form.Field>

                                <Form.Field className={classes.control}>
                                    <input
                                        placeholder="Password"
                                        type="password"
                                        {...register('confirmPassword', {
                                            required: true,
                                        })}
                                    />
                                    {errors.confirmPassword && (
                                        <p className={classes.message}>
                                            Please check to match Password
                                        </p>
                                    )}
                                </Form.Field>
                                <Button className={classes.btn} type="submit">
                                    Submit
                                </Button>
                            </Form>
                        </Hexagon>
                    </div>
                    <div className="col-6">
                        <RestorePassword
                            responseStatus={responseStatusHandler}
                        />
                    </div>
                </div>
            </div>

            <div className={` ${isKeyboardOpen ? `${classes.linkTitleKeyboard}` : `${classes.linkTitleSignup}`}`} >
                <LinkVideos />
            </div>

        </div>
    )
}

export default Signup
