import React, { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'

import Login from './components/Login/Login'
import Home from './components/Home/Home'
import HexProvider from './store/hex/HexProvider'
import AuthContext from './store/auth/auth-context'
import Admin from './components/Admin/Admin'
import AdminProvider from './store/admin/AdminProvider'
import TagManager from 'react-gtm-module'
import PopupProvider from './store/popup/PopupProvider'
import BoardProvider from './store/board/BoardProvider'

import HexagonBrowser from './components/Home/HexagonItem/hexagonBrowser'
import Protected from './store/admin/Protected'

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_AUTH,
}

TagManager.initialize(tagManagerArgs)
    
function App() {
    const ctx = useContext(AuthContext)
    const isLoggedIn = ctx.isLoggedIn
    return (
        <React.Fragment>
            <HexagonBrowser/>
            <Routes>
                <Route
                    path="/"
                    element={
                        <main>
                            {!isLoggedIn && !ctx.shareMode && <Login />}
                            {ctx.stage === 'board' && (
                                <HexProvider>
                                    <PopupProvider>
                                        <BoardProvider>
                                            <React.Suspense fallback={<div />}>
                                                <Home />
                                            </React.Suspense>
                                        </BoardProvider>
                                    </PopupProvider>
                                </HexProvider>
                            )}
                        </main>
                    }
                />
                <Route
                    path="/admin"
                    element={
                        <AdminProvider>
                          <Protected Component={Admin } />
                        </AdminProvider>
                    }
                />
            </Routes>
        </React.Fragment>
    )
}

export default App
