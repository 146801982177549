import React, { useState, useEffect, useRef, useContext } from 'react'
import classes from './HexagonItem.module.css'
import HexContext from '../../../store/hex/hex-context'
import AuthContext from '../../../store/auth/auth-context'
import PropTypes from 'prop-types'
import BoardContext from '../../../store/board/board-context'
import useViewPort from '../../../hooks/useViewPort'

const HexagonItem = ({ init, ...props }) => {
    const ctx = useContext(HexContext)
    const authCtx = useContext(AuthContext)
    const boardCtx = useContext(BoardContext)
    const [mobile] = useViewPort()
    const itemRef = useRef(null)
    const editRef = useRef(null)
    const contentRef = useRef('')

    const [blur, setBlur] = useState(false)
    const [forceWrap, setForceWrap] = useState(false)
    const [currentKey, setCurrentKey] = useState('')
    const [hovered, setHovered] = useState(false)
    const [dragging, setDragging] = useState(false)
    const [focused, setFocused] = useState(false)
    const [isTemp, setIsTemp] = useState(
        (!props.color || props.color === '#d3d3d3') &&
            !props.content?.length &&
            !props.golden
    )
    const [draggable, setDraggable] = useState(false)
    const isActive = ctx.activeHex === props.index
    const [androidDropZoneId, setAndroidDropZoneId] = useState()
    let browserData = JSON.parse(sessionStorage.getItem("browser"));
    const row =
        props.hexY % 2 !== 0
            ? 2 * props.hexX + '/span 2'
            : 2 * props.hexX - 1 + '/span 2'
    const position = {
        '--counter': props.hexX,
        gridColumn: props.hexP + '/span 3',
        gridRow: row,
    }

    useEffect(() => {
        editRef.current?.addEventListener('focus', selectionHandler)
        editRef.current?.addEventListener('focusout', (e) => {
            handleBlur(e)
        })
        return () => {
            editRef.current?.removeEventListener('focusout', (e) => {
                handleBlur(e)
            })
            editRef.current?.removeEventListener('focus', selectionHandler)
        }
    }, [])

    useEffect(() => {
        if (boardCtx.zoomedHexId === props.index) {
            props.zoomTo(itemRef.current, props.scale)
        }
    }, [boardCtx.zoomedHexId, boardCtx?.timeline?.future[0]?.data])

    useEffect(() => {
        if (props.content !== undefined && props.content !== false) {
            contentRef.current = props.content
            const textSplit = props.content.split(' ')
            const longestWord = textSplit.sort(function (a, b) {
                return b.length - a.length
            })
            longestWord[0].length > 6 && setForceWrap(true)
            updateMargin(editRef.current)
        }
        else{
            contentRef.current = ''
        }
    }, [props.content])

    useEffect(() => {
        if (isActive && props.panningGrid) {
            setHovered(false)
            setFocused(false)
            ctx.enableHexItem(null)
            props.contentHandler(contentRef.current, props.index)
            editRef.current.blur()
        }
    }, [isActive, props.panningGrid])

    useEffect(() => {
        if (init) {
            props.zoomTo(itemRef.current, props.scale)
        }
    }, [init, boardCtx.operationCheck])

    useEffect(() => {
        if (props.golden || (props.color && props.color !== '#d3d3d3')) {
            setIsTemp(false)
        }
    }, [props.golden, props.color])

    useEffect(() => {
        if (props.content?.length) {
            setIsTemp(false)
        } else {
            setIsTemp(true)
        }
    }, [props.content])

    useEffect(() => {
        if (isActive && !mobile) {
            focusInput({ element: editRef })
        }
    }, [props.bold, props.italic, props.color, props.golden, mobile])

    useEffect(() => {
        if (mobile) {
            setHovered(isActive)
        }
    }, [isActive])

    function selectionHandler() {
        window.getSelection().removeAllRanges()
        selectionTimeout = setTimeout(() => {
            const range = document.createRange()
            const sel = window.getSelection()
            const lastChild = this.lastChild
            if (lastChild) {
                range.setStartAfter(lastChild)
            } else {
                const textNode = document.createTextNode('\xa0')
                this.appendChild(textNode)
                range.setStartAfter(textNode)
            }
            range.collapse(true)
            sel.removeAllRanges()
            sel.addRange(range)
            editRef.current.style.caretColor = 'auto'
        }, 400)
    }

    const zoomToElement = () => {
        const boardEl = document.getElementById('hexagonCanvas')
        const { offsetLeft: elOffsetLeft, offsetTop: elOffsetTop } =
            itemRef.current
        const { scale, positionX, positionY } = props.transformState
        const posX = elOffsetLeft * scale - Math.abs(positionX),
            posY = elOffsetTop * scale - Math.abs(positionY)
        const isInView =
            posX >= 0 &&
            posY >= 0 &&
            posX <= boardEl.clientWidth - 130 &&
            posY <= boardEl.clientHeight - 120
        if ((!mobile && !isInView) || mobile) {
            const { clientHeight, clientWidth } =
                document.getElementsByClassName('react-transform-wrapper')[0]
            const { offsetLeft, offsetTop } = itemRef.current
            let x = offsetLeft * scale - (clientWidth - 70 * scale) / 2
            let y = offsetTop * scale - clientHeight / 5
            const { width: transformElWidth, height: transformElHeight } =
                document
                    .getElementsByClassName('react-transform-component')[0]
                    .getBoundingClientRect()
            const maxX = transformElWidth - clientWidth
            const maxY = transformElHeight - clientHeight
            if (x > maxX) {
                x = maxX
            }
            if (y > maxY) {
                y = maxY
            }
            if (x < 0) {
                x = 0
            }
            if (y < 0) {
                y = 0
            }
            props.setTransform(-x, -y, scale)
        } else {
            props.setTransform(positionX, positionY, scale)
        }
    }
    const focusInput = () => {
        if (focused) {
            return
        }
        zoomToElement()
        editRef.current.focus({
            preventScroll: true,
        })
        setBlur(true)
    }

    const backgroundColor = props.color
        ? props.color
        : isActive || contentRef.current.length
        ? '#d3d3d3'
        : props.color

    const onEditHexHandler = () => {
        // setHovered(true)
        ctx.enableHexItem(props.index)
        focusInput({ element: editRef })
    }

    const setContent = (e) => {
        updateMargin(e.target)
        boardCtx.setChanged(true)
        const currentContent = e.currentTarget.textContent
        if (
            currentContent.length === 0 &&
            !props.golden &&
            (!props.color || props.color === '#d3d3d3')
        ) {
            setIsTemp(true)
        }
        const textSplit = currentContent.split(' ')
        let prevValue = props.content
        const longestWord = textSplit.sort(function (a, b) {
            return b.length - a.length
        })
        setForceWrap(longestWord[0].length > 6)
        const backSpaceEvent =
            currentKey === 'delete' && currentKey === 'backspace'
        // if (editRef.current.clientHeight > 76 && !backSpaceEvent) {
        if ( (editRef.current.clientHeight > 76) || currentContent.length > process.env.REACT_APP_TEXT_MAX_LENGTH && !backSpaceEvent) {
           
            e.currentTarget.blur()
            editRef.current.innerText = prevValue
            updateMargin(e.target)
            props.popupHandler(true)
            return
        }
        if (currentContent.length > 0) {
            setIsTemp(false)
        }
        prevValue = currentContent
        contentRef.current = prevValue
    }

    const updateMargin = (hex) => {
        let margin = 0
        let lines = 1
        let text = hex
        let styles = window.getComputedStyle(text)
        let lineHeight = styles.lineHeight
        let lineHeightParsed = parseFloat(lineHeight.split('px')[0])
        let contentLength = text.innerText.length
        lines = Math.ceil(contentLength / 15)
        margin =
            Math.round(lines) < 7
                ? lineHeightParsed * 2.5 -
                  (Math.round(lines) - 1) * (lineHeightParsed / 2) +
                  'pxx'
                : '0px'
        text.style.marginTop = margin
    }

    const selectHandler = () => {
        if (!ctx.copyMode) {
            if (!ctx.selectedHexagons.includes(props.index)) {
                ctx.setSelected(props.index)
            } else {
                ctx.removeSelected(props.index)
            }
        }
    }

    const copyActionHandler = () => {
        if (ctx.selectMode === true) {
            props.multiCopyHandler(props.index, props.hexX, props.hexY)
        } else {
            if (ctx.copyData !== undefined) {
                props.copyContentDataHandler(props.index)
            }
        }
        onEditHexHandler()
    }

    const clickHandler = () => {
        // if (props.isPanning) {
        //     return
        // }
        if (authCtx.shareMode || ctx.playBackMode) {
            return
        }
        if (!dragging && !hovered && props.isMobile && props.color) {
            setHovered(true)
        }
        if (ctx.colorPicker || ctx.addHexMode) {
            ctx.disableModes()
        }
        if (ctx.selectMode && props.color !== false && !ctx.copyMode) {
            selectHandler()
        } else if (ctx.copyMode) {
            copyActionHandler()
        } else if (!ctx.selectMode && !ctx.copyMode) {
            onEditHexHandler()
        }
    }

    const focusHandler = () => {
        if (ctx.selectMode && props.color !== false && !ctx.copyMode) {
            selectHandler()
        } else if (!ctx.selectMode && !ctx.copyMode) {
            onEditHexHandler()
        }
    }



    let selectionTimeout
    const handleBlur = (e) => {
        if (selectionTimeout) {
            clearTimeout(selectionTimeout)
        }
        if (!e.currentTarget?.contains(e.relatedTarget)) {
            setFocused(false)
            if (blur) {
                props.contentHandler(contentRef.current, props.index)
            }
            props.panningHandler(false)
        }
        editRef.current.style.caretColor = 'transparent'
    }

    const onDrop = (e) => {
        if (!props.color) {
            let tranferedId = e.dataTransfer.getData('id')
            if ( tranferedId != "" && typeof parseInt(tranferedId) === "number" && parseInt(tranferedId) > 0){
                props.swapHexagons(tranferedId, props.index)
                ctx.enableHexItem(props.index)

                setTimeout(() => {
                    setFocused(false)
                }, 100)
                setHovered(true)
            }else{
                props.setDropZoneIndex(undefined)
            }
        
        }
        
    }

    const onDragStart = (e) => {
        ctx.enableHexItem(props.index)
        setHovered(false)
        setDragging(true)
        setFocused(false)
        e.dataTransfer.setData('id', props.index)
    }

    const onDragEnd = () => {
        setDragging(false)
        props.panningHandler(false)
        props.setDropZoneIndex(null)
    }
    const onKeyDown = (e) => setCurrentKey(e.key.toLowerCase())
    const onDragOver = (e) => e.preventDefault()
    const onMouseEnter = () => {
        if (!dragging && !props.isMobile) {
            setHovered(true)
        }
    }
    const onMouseLeave = () => {
        setHovered(false)
    }
    const onDragEnter = () => {        
        if (!props.color) {
            props.setDropZoneIndex(props.index)
        } else {
            props.setDropZoneIndex(undefined)
        }
    }
    const onMouseUp = () => {
        props.panningHandler(false)
        setDragging(false)
    }
    const onTouchStart = (e) => {
        const targetClassName = e.target.className
        setDraggable(targetClassName.includes('dragIconWrapper'))
        if (targetClassName.includes('dragIconWrapper')) {
            props.contentHandler(contentRef.current, props.index)
            setDragging(true)
            setFocused(false)
            props.panningHandler(true)
        }

        if (
            targetClassName.includes('textItemValue') &&
            props.index === ctx.activeHex
        ) {
            props.panningHandler(true)
        }
    }
    const onTouchMove = (e) => {
        if (browserData.ios) {
            return
        }
        if (dragging) {
            const touchCursor = document.getElementById('dragCursor')
            touchCursor.style.left = `${e.touches[0].clientX}px`
            touchCursor.style.top = `${e.touches[0].clientY}px`
            touchCursor.style.display = 'unset'
            const dropZoneId = document
                .elementsFromPoint(e.touches[0].clientX, e.touches[0].clientY)
                ?.find((el) => el.id.includes('hexagonItem'))
                ?.id.replace('hexagonItem', '')
            setAndroidDropZoneId(dropZoneId)
        }
    }
    const onTouchEnd = () => {
        if (!browserData.ios) {
            const touchCursor = document.getElementById('dragCursor')
            touchCursor.style.display = 'none'
            if (androidDropZoneId) {
                props.swapHexagons(androidDropZoneId, props.index)
                ctx.enableHexItem(null)
            }
        }
        setDragging(false)
        props.panningHandler(false)
        setAndroidDropZoneId(null)
    }
    const dragIconVisible =
        hovered &&
        !authCtx.shareMode &&
        !ctx.playBackMode &&
        !ctx.selectMode &&
        !isTemp &&
        (isActive || props.color)

    return (
        <>
            <div
                key={props.index}
                id={`hexagonItem${props.index}`}
                draggable={draggable}
                ref={itemRef}
                onClick={clickHandler}
                onFocus={focusHandler}
                onDrop={onDrop}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onDragEnter={onDragEnter}
                onDragOver={onDragOver}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onKeyDown={onKeyDown}
                onMouseDown={onTouchStart}
                onMouseUp={onMouseUp}
                onBlur={handleBlur}
                // onFocus={() => setFocused(true)}
                onTouchStart={onTouchStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onTouchEnd}
                data-position={props.color}
                data-x={props.hexX}
                data-y={props.hexY}
                style={position}
                className={`
             ${props.dropZoneIndex === props.index && classes.highlighted ?  classes.highlighted : ''}
             ${classes.hexagon} 
             ${classes[props.color !== false || isActive ? 'active' : '']}
             ${classes[props.golden ? 'golden' : 'not-golden']}
             ${
                 classes[
                     props.color !== false &&
                     ctx.selectedHexagons.includes(props.index) === true
                         ? 'selected'
                         : ''
                 ]
             }
             ${hovered ? classes.hoveredHexagon : ''}
             ${`hexagonItem${props.index}`}
             `}
            >
                <div className={classes.content}>
                    {dragIconVisible && (
                        <div className={classes.dragIconWrapper}>
                            <img
                                className={classes.dragIcon}
                                src={require('../../../assets/drag.png')}
                            />
                        </div>
                    )}
                    <div className={classes.box}>
                        <div
                            style={{ backgroundColor }}
                            className={classes.overlay}
                        />
                        <div className={classes.textcontent}>
                            <div className={classes.shapeLeft} />
                            <div className={classes.shapeRight} />
                            <div className={classes.textItem}>
                                <div
                                    tabIndex={1}
                                    ref={editRef}
                                    className={`${classes.textItemValue} 
                                        ${props.italic ? classes.italic : ''} 
                                        ${props.bold ? classes.bold : '' } 
                                        ${forceWrap ? classes[forceWrap && 'anywhere'] : ''}
                                        ${ browserData.firefox >= 0 ?  classes.textItemValueFirefox : ''}
                                        ${ browserData.ios || browserData.safari ? classes.textItemValueIos : ''}`}
                                    contentEditable={true}
                                    suppressContentEditableWarning={true}
                                    onInput={setContent}
                                >
                                    {props?.content}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

HexagonItem.propTypes = {
    init: PropTypes.bool,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    index: PropTypes.number,
    zoomTo: PropTypes.func,
    hexX: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hexY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hexP: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    panningHandler: PropTypes.func,
    setActive: PropTypes.func,
    popupHandler: PropTypes.func,
    contentHandler: PropTypes.func,
    multiCopyHandler: PropTypes.func,
    copyContentDataHandler: PropTypes.func,
    golden: PropTypes.bool,
    italic: PropTypes.bool,
    bold: PropTypes.bool,
    swapHexagons: PropTypes.func,
    setDropZoneIndex: PropTypes.func,
    dropZoneIndex: PropTypes.number,
    scale: PropTypes.number,
    panningDisabled: PropTypes.bool,
    isPanning: PropTypes.bool,
    transformState: PropTypes.object,
    setTransform: PropTypes.func,
    isMobile: PropTypes.bool,
    panningGrid: PropTypes.bool,
}

export default React.memo(HexagonItem)
