import React, { useReducer } from 'react'
import PopupContext from './popup-context'
import PropTypes from 'prop-types'

const defaultPopupState = {
    popupProps: {},
}

const popupReducer = (state, action) => {
    switch (action.type) {
        case 'SET_POPUP_PROPS':
            return {
                ...state,
                popupProps: action.payload,
            }
        case 'RESET':
            return defaultPopupState
        default:
            return state
    }
}

const PopupProvider = (props) => {
    const [popupState, dispatcPopupAction] = useReducer(
        popupReducer,
        defaultPopupState
    )

    const setPopupProps = (payload) => {
        dispatcPopupAction({ type: 'SET_POPUP_PROPS', payload })
    }
    const reset = () => {
        dispatcPopupAction({ type: 'RESET' })
    }
    const popupContent = {
        popupProps: popupState.popupProps,
        setPopupProps,
        reset,
    }

    return (
        <PopupContext.Provider value={popupContent}>
            {props.children}
        </PopupContext.Provider>
    )
}

PopupProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default PopupProvider
