import React, { useReducer } from 'react'
import AdminContext from './admin-context'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

const defaultState = {
    activityData: {},
    activityDataLoading: false,
    activityDataError: false,
    activityContent: null,
    users: null,
}

const adminReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ACTIVITY_DATA':
            return {
                ...state,
                activityData: action.payload,
            }
        case 'SET_ACTIVITY_DATA_LOADING':
            return {
                ...state,
                activityDataLoading: action.payload,
            }
        case 'SET_ACTIVITY_DATA_ERROR':
            return {
                ...state,
                activityDataError: action.payload,
            }
        case 'SET_ACTIVITY_CONTENT':
            return {
                ...state,
                activityContent: action.payload,
            }
        case 'SET_USERS':
            return {
                ...state,
                users: action.payload,
            }
        case 'RESET':
            return defaultState
        default:
            return state
    }
}

const AdminProvider = (props) => {
    const [adminState, dispatchAdminAction] = useReducer(
        adminReducer,
        defaultState
    )
    const setActivityData = (payload) => {
        dispatchAdminAction({ type: 'SET_ACTIVITY_DATA', payload })
    }
    const setActivityDataLoading = (payload) => {
        dispatchAdminAction({ type: 'SET_ACTIVITY_DATA_LOADING', payload })
    }
    const setActivityDataError = (payload) => {
        dispatchAdminAction({ type: 'SET_ACTIVITY_DATA_ERROR', payload })
    }
    const setActivityContent = (payload) => {
        dispatchAdminAction({ type: 'SET_ACTIVITY_CONTENT', payload })
    }
    const setUsers = (payload) => {
        dispatchAdminAction({ type: 'SET_USERS', payload })
    }
    const reset = (payload) => {
        dispatchAdminAction({ type: 'RESET', payload })
    }

    const getActivities = (filters) => {
        let formattedFilters = { ...filters }
        if (formattedFilters?.createdDate) {
            formattedFilters.createdDate = dayjs(
                formattedFilters.createdDate
            ).format('YYYY-MM-DD')
        } else {
            delete formattedFilters.createdDate
        }
        if (formattedFilters?.endDate) {
            formattedFilters.endDate = dayjs(
                new Date(formattedFilters.endDate)
            ).format('YYYY-MM-DD')
        } else {
            delete formattedFilters.endDate
        }
        formattedFilters = new URLSearchParams(formattedFilters).toString()
        setActivityDataLoading(true)
        fetch(
            `${process.env.REACT_APP_API_URL}/user/activity?${formattedFilters}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                },
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response.status) {
                    setActivityDataError(null)
                    setActivityData(response)
                } else {
                    setActivityData(response)
                    setActivityDataError(response.message)
                }
                setActivityDataLoading(false)
            })
    }
    const getUsers = (initials) => {
        fetch(
            `${process.env.REACT_APP_API_URL}/user/find?username=${initials}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                },
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response.status) {
                    setUsers(response.listOfUsers)
                } else {
                    setUsers(null)
                }
            })
    }
    const getActivityContent = (activityId, batchId = null, onSuccess) => {
        setActivityDataLoading(true)
        fetch(
            `${
                process.env.REACT_APP_API_URL
            }/user/activity/content?activityId=${activityId}${
                batchId ? `&batchId=${batchId}` : ''
            }`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
                },
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response.status) {
                    setActivityContent(response)
                    if (onSuccess) {
                        onSuccess()
                    }
                } else {
                    setActivityContent(null)
                }
                setActivityDataLoading(false)
            })
    }

    const adminContent = {
        ...adminState,
        reset,
        getActivities,
        getActivityContent,
        getUsers,
    }

    return (
        <AdminContext.Provider value={adminContent}>
            {props.children}
        </AdminContext.Provider>
    )
}

AdminProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default AdminProvider
