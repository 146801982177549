import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/* eslint-disable */
class AccordionItem extends Component {
    static propTypes = {
        title: PropTypes.object,
        atomic: PropTypes.bool,
        children: PropTypes.node.isRequired,
        isOpen: PropTypes.bool.isRequired,
        setIsOpen: PropTypes.func.isRequired,
        id: PropTypes.string,
        playBackMode: PropTypes.bool,
    }

    static defaultProps = {
        title: 'TITLE',
    }

    constructor(props) {
        super(props)
        // this.state = { isOpen: false }
        this.mounted = true
    }

    handleDocumentClick = (event) => {
        if (
            // eslint-disable-next-line
            this.mounted &&
            !ReactDOM.findDOMNode(this).contains(event.target) &&
            this.props.isOpen
        ) {
            this.props.setIsOpen(false)
        }
    }

    componentDidMount() {
        if (this.props.atomic) {
            const titles = document.querySelectorAll('.accordion-item .title')
            titles.forEach((title) => {
                title.addEventListener('click', this.handleDocumentClick, false)
                title.addEventListener(
                    'touchend',
                    this.handleDocumentClick,
                    false
                )
            })
        }
    }

    componentWillUnmount() {
        this.mounted = false
        document.removeEventListener('click', this.handleDocumentClick, false)
        document.removeEventListener(
            'touchend',
            this.handleDocumentClick,
            false
        )
    }

    onClick = () => {
        this.props.setIsOpen(!this.props.isOpen)
    }

    render() {
        const accordionItemClassNames = classNames([
            'accordion-item',
            {
                active: this.props.isOpen,
            },
        ])

        return (
            <div className={accordionItemClassNames}>
                <button
                    className="title"
                    id={this.props.id}
                    onClick={this.onClick}
                >
                    {this.props.title}
                </button>
                <div
                    id="accordionSection"
                    className={`panel ${
                        this.props.playBackMode && 'accordionSectionOpen'
                    }`}
                >
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default AccordionItem
