import React, { useEffect, useState } from 'react'

import classes from './VersionBack.module.css'
import PropTypes from 'prop-types'

const VersionBack = React.forwardRef((props, ref) => {
    const [active, setActive] = useState(false)

    useEffect(() => {
        setActive(props.currentVersion <= 0)
    }, [props.currentVersion, props.versionsMax])

    const forwardHandler = () => {
        !active && props.versionHandler(props.currentVersion - 1)
    }

    return (
        <div
            ref={ref}
            className={classes.button}
            onClick={forwardHandler}
            disabled={active}
        ></div>
    )
})

VersionBack.displayName = 'VersionBack'

VersionBack.propTypes = {
    currentVersion: PropTypes.number,
    versionsMax: PropTypes.number,
    versionHandler: PropTypes.func,
}

export default VersionBack
