import { useState, useEffect } from 'react'

export default function useViewPort() {
    const [mobile, setMobile] = useState(false)
    const [landscape, setLandscape] = useState(false)
    const [width, setWidth] = useState()
    const [height, setHeight] = useState()

    useEffect(() => {
        const initialWidth = document.documentElement.clientWidth
        const initialHeight = document.documentElement.clientHeight
        setWidth(initialWidth)
        setHeight(initialHeight)

        setMobile(Math.max(initialHeight, initialWidth) < 1024)
        setLandscape(initialWidth > initialHeight)

        const handleResize = () => {
            const width = document.documentElement.clientWidth
            const height = document.documentElement.clientHeight
            setLandscape(width > height)
            if (width > height) {
                setMobile(Math.max(width, height) < 1024)
            } else {
                setMobile(Math.max(width, height) < 1200)
            }
            setWidth(width)
            setHeight(height)
        }

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return [mobile, landscape, width, height]
}
