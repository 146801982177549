import React, { useEffect, useReducer } from 'react'
import AuthContext from './auth-context'
import PropTypes from 'prop-types'

const defaultState = {
    isLoggedIn: false,
    userName: null,
    token: null,
    boardTitle: '',
    boardMessage: false,
    stage: null,
    boardData: {},
    boardError: '',
    userBoards: null,
    shareId: null,
    shareMode: false,
    guestMode: false,
    restoreToken: null,
    restoreMode: false,
    versions: [],
    boardImage: null,
    isNewUser: false,
    isOnline: true,
    dataLoading: true,
    boardName: null,
}

const authReducer = (state, action) => {
    switch (action.type) {
        case 'SET_IS_LOGGED_IN':
            return {
                ...state,
                isLoggedIn: action.payload,
            }
        case 'SET_USERNAME':
            return {
                ...state,
                userName: action.payload,
            }
        case 'SET_TOKEN':
            return {
                ...state,
                token: action.payload,
            }
        case 'SET_BOARD_TITLE':
            return {
                ...state,
                boardTitle: action.payload,
            }
        case 'SET_BOARD_MESSAGE':
            return {
                ...state,
                boardMessage: action.payload,
            }
        case 'SET_STAGE':
            return {
                ...state,
                stage: action.payload,
            }
        case 'SET_BOARD_DATA':
            return {
                ...state,
                boardData: action.payload,
            }
        case 'SET_BOARD_ERROR':
            return {
                ...state,
                boardError: action.payload,
            }
        case 'SET_USER_BOARDS':
            return {
                ...state,
                userBoards: action.payload,
            }
        case 'SET_SHARE_ID':
            return {
                ...state,
                shareId: action.payload,
            }
        case 'SET_SHARE_MODE':
            return {
                ...state,
                shareMode: action.payload,
            }
        case 'SET_RESTORE_TOKEN':
            return {
                ...state,
                restoreToken: action.payload,
            }
        case 'SET_RESTORE_MODE':
            return {
                ...state,
                restoreMode: action.payload,
            }
        case 'SET_VERSIONS':
            return {
                ...state,
                versions: action.payload,
            }
        case 'SET_BOARD_IMAGE':
            return {
                ...state,
                boardImage: action.payload,
            }
        case 'SET_IS_NEW_USER':
            return {
                ...state,
                isNewUser: action.payload,
            }
        case 'SET_IS_ONLINE':
            return {
                ...state,
                isOnline: action.payload,
            }
        case 'SET_DATA_LOADING':
            return {
                ...state,
                dataLoading: action.payload,
            }
        case 'SET_BOARD_NAME':
            return {
                ...state,
                boardName: action.payload,
            }
        case 'RESET':
            return defaultState
        default:
            return state
    }
}

const AuthProvider = (props) => {
    const isReachable = require('is-reachable')
    const [authState, dispatchAuthAction] = useReducer(
        authReducer,
        defaultState
    )
    useEffect(() => {
        const searchBoard = getSearchParameters()
        const restoreParams = getRestoreParameters()
        if (searchBoard) {
            dispatchAuthAction({ type: 'SET_SHARE_MODE', payload: true })
            if (searchBoard.type && encodeURI(searchBoard.type) === 'version') {
                getBoardVersionHandler(searchBoard.id)
            } else {
                getShareBoardHandler(searchBoard.id)
            }
            dispatchAuthAction({ type: 'SET_STAGE', payload: 'board' })
            return
        }
        if (restoreParams) {
            dispatchAuthAction({ type: 'SET_STAGE', payload: 'reset' })
            dispatchAuthAction({ type: 'SET_RESTORE_MODE', payload: true })
            dispatchAuthAction({
                type: 'SET_RESTORE_TOKEN',
                payload: restoreParams,
            })
            return
        }
        const user = sessionStorage.getItem('login')
        const token = sessionStorage.getItem('token')
        const isNewUser = sessionStorage.getItem('isNewUser')
        if (
            user !== null &&
            token !== null &&
            user !== 'undefined' &&
            token !== 'undefined' &&
            token !== undefined &&
            isNewUser !== undefined &&
            isNewUser !== 'undefined'
        ) {
            dispatchAuthAction({
                type: 'SET_USERNAME',
                payload: user.toLowerCase(),
            })
            dispatchAuthAction({ type: 'SET_TOKEN', payload: token })
            dispatchAuthAction({
                type: 'SET_IS_NEW_USER',
                payload: JSON.parse(isNewUser) === true,
            })
        } else {
            dispatchAuthAction({ type: 'SET_STAGE', payload: 'intro' })
        }
    }, [])

    useEffect(() => {
        if (
            authState.userName !== null &&
            authState.token !== null &&
            authState.userName !== 'undefined' &&
            authState.token !== 'undefined' &&
            authState.token !== undefined &&
            !authState.guestMode
        ) {
            dispatchAuthAction({ type: 'SET_IS_LOGGED_IN', payload: true })
            dispatchAuthAction({ type: 'SET_STAGE', payload: 'board' })
            getBoardsHandler()
        }
    }, [authState.userName, authState.token, authState.guestMode])

    useEffect(() => {
        if (authState.boardName) {
            shareLinkHandler()
        }
    }, [authState.boardName])

    const loginHandler = (email, token, isNewUser, rolesList) => {
        sessionStorage.setItem('login', email.toLowerCase())
        sessionStorage.setItem('token', token)
        sessionStorage.setItem('isNewUser', isNewUser)
        sessionStorage.setItem('rolesList', rolesList)
        dispatchAuthAction({ type: 'SET_IS_LOGGED_IN', payload: true })
        dispatchAuthAction({ type: 'SET_USERNAME', payload: email })
        dispatchAuthAction({ type: 'SET_TOKEN', payload: token })
        dispatchAuthAction({ type: 'SET_STAGE', payload: 'board' })
        dispatchAuthAction({ type: 'SET_IS_NEW_USER', payload: isNewUser })
    }

    const logoutHandler = () => {
        sessionStorage.clear()
        dispatchAuthAction({ type: 'RESET' })
        dispatchAuthAction({ type: 'SET_STAGE', payload: 'intro' })
    }

    const stageHandler = (stage) => {
        dispatchAuthAction({ type: 'SET_STAGE', payload: stage })
    }

    const getSearchParameters = () => {
        const search = new URLSearchParams(window.location.search.slice(1))
        if (search && !!search.get('board')) {
            return {
                id: search.get('board'),
                type: search.get('type'),
            }
        } else return false
    }

    const getRestoreParameters = () => {
        const board = new URLSearchParams(window.location.search.slice(1))
        return board != null && board !== '' ? board.get('token') : false
    }

    const shareIdHandler = (id) => {
        dispatchAuthAction({ type: 'SET_SHARE_ID', payload: id })
    }

    const getBoardsHandler = (save) => {
        !save && dispatchAuthAction({ type: 'SET_DATA_LOADING', payload: true })
        fetch(
            process.env.REACT_APP_API_URL +
                '/user/dashboards/' +
                encodeURIComponent(authState.userName).toLowerCase(),
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authState.token}`,
                },
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                dispatchAuthAction({
                    type: 'SET_USER_BOARDS',
                    payload: response,
                })
                if (save !== 'save') {
                    const defaultBoard = response.filter(
                        (board) => board.default === true
                    )
                    defaultBoard.length &&
                        getBoardHandler(defaultBoard[0]['boardName'])
                }
                dispatchAuthAction({ type: 'SET_DATA_LOADING', payload: false })
            })
            .catch(() => {
                dispatchAuthAction({ type: 'SET_DATA_LOADING', payload: false })
            })
    }

    const getBoardHandler = (name) => {
        dispatchAuthAction({ type: 'SET_BOARD_NAME', payload: name })
        dispatchAuthAction({ type: 'SET_DATA_LOADING', payload: true })
        fetch(
            process.env.REACT_APP_API_URL +
                '/user/dashboard/' +
                encodeURIComponent(name),
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authState.token}`,
                },
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response.status) {
                    dispatchAuthAction({
                        type: 'SET_BOARD_DATA',
                        payload: response.data,
                    })
                    dispatchAuthAction({
                        type: 'SET_BOARD_MESSAGE',
                        payload: response.boardMessage,
                    })
                    dispatchAuthAction({
                        type: 'SET_BOARD_IMAGE',
                        payload: response.previewImageUrl,
                    })
                    if (response.title) {
                        boardTitleHandler(response.title)
                    }
                }
                dispatchAuthAction({ type: 'SET_DATA_LOADING', payload: false })
            })
            .catch(() => {
                dispatchAuthAction({ type: 'SET_DATA_LOADING', payload: false })
            })
    }

    const getShareBoardHandler = (name) => {
        fetch(
            process.env.REACT_APP_API_URL +
                '/user/dashboard/share/' +
                encodeURIComponent(name),
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response.status) {
                    dispatchAuthAction({
                        type: 'SET_BOARD_DATA',
                        payload: response.data,
                    })
                    dispatchAuthAction({
                        type: 'SET_BOARD_MESSAGE',
                        payload: response.boardMessage,
                    })
                } else {
                    dispatchAuthAction({
                        type: 'SET_BOARD_ERROR',
                        payload: response.message,
                    })
                }
            })
    }
    const getBoardVersionHandler = (id) => {
        fetch(
            process.env.REACT_APP_API_URL +
                '/user/dashboard/version/' +
                encodeURIComponent(id),
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response.status) {
                    dispatchAuthAction({
                        type: 'SET_BOARD_DATA',
                        payload: response.data,
                    })
                    dispatchAuthAction({
                        type: 'SET_BOARD_MESSAGE',
                        payload: response.message,
                    })
                }
            })
    }
    useEffect(() => {
        let consecutiveFalseCount = 0
        setInterval(() => {
            (async () => {
                const reachable = await isReachable(process.env.REACT_APP_API_URL)
                if (reachable === false) {
                    consecutiveFalseCount++

                    if (consecutiveFalseCount === 3) {
                        dispatchAuthAction({ type:'SET_IS_ONLINE', payload: false })
                       
                    }
                } else if (reachable === true) {
                    consecutiveFalseCount = 0
                    sessionStorage.removeItem("onlineCheck");
                    dispatchAuthAction({ type:'SET_IS_ONLINE', payload: true })
                }
            })()
        }, 5000)
    }, [])

    const shareLinkHandler = () => {
        const data = {
            accessType: false,
            boardName: authState.boardName,
            userRef: false,
        }
        fetch(process.env.REACT_APP_API_URL + '/user/dashboard/share', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authState.token}`,
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response.status) {
                    shareIdHandler(response.shareId)
                }
            })
    }

    const getVersionsHandler = (versions) => {
        dispatchAuthAction({ type: 'SET_VERSIONS', payload: versions })
    }

    const deleteBoardHandler = (id, onSuccess) => {
        fetch(
            process.env.REACT_APP_API_URL +
                '/user/deletedashboard/' +
                encodeURIComponent(id),
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authState.token}`,
                },
            }
        ).then(() => {
            let newUserBoards = [...authState.userBoards]
            const boards = newUserBoards.filter((item) => item.boardName !== id)
            dispatchAuthAction({ type: 'SET_USER_BOARDS', payload: boards })
            if (onSuccess) {
                onSuccess()
            }
        })
    }

    const boardMessageHandler = (message) => {
        dispatchAuthAction({ type: 'SET_BOARD_MESSAGE', payload: message })
    }

    const boardNameHandler = (name) => {
        dispatchAuthAction({ type: 'SET_BOARD_NAME', payload: name })
    }

    const boardTitleHandler = (name) => {
        dispatchAuthAction({ type: 'SET_BOARD_TITLE', payload: name })
    }

    const guestModeHandler = (state) => {
        dispatchAuthAction({ type: 'SET_GUEST_MODE', payload: state })
    }

    const restoreModeHandler = (state) => {
        dispatchAuthAction({ type: 'SET_RESTORE_MODE', payload: state })
    }
    const setIsNewUser = (payload) => {
        dispatchAuthAction({ type: 'SET_IS_NEW_USER', payload })
    }
    const setIsOnline = (payload) => {
        dispatchAuthAction({ type: 'SET_IS_ONLINE', payload })
    }

    const authContent = {
        ...authState,
        boards: authState.userBoards,
        onLogin: loginHandler,
        onLogout: logoutHandler,
        stageHandler: stageHandler,
        boardMessageHandler: boardMessageHandler,
        shareIdHandler: shareIdHandler,
        boardNameHandler: boardNameHandler,
        boardTitleHandler: boardTitleHandler,
        getBoardHandler: getBoardHandler,
        getBoardsHandler: getBoardsHandler,
        guestModeHandler: guestModeHandler,
        deleteBoardHandler: deleteBoardHandler,
        restoreModeHandler: restoreModeHandler,
        getVersionsHandler: getVersionsHandler,
        setIsNewUser,
        setIsOnline,
    }

    return (
        <AuthContext.Provider value={authContent}>
            {props.children}
        </AuthContext.Provider>
    )
}

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default AuthProvider
