import React, { useContext } from 'react'

import classes from './RestorePassword.module.css'
import Hexagon from '../Hexagon/Hexagon'
import { useForm } from 'react-hook-form'
import { Form, Button } from 'semantic-ui-react'
import AuthContext from '../../../store/auth/auth-context'
import PropTypes from 'prop-types'

const RestorePassword = (props) => {
    const authCtx = useContext(AuthContext)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const onSubmit = (data) => {
        fetch(process.env.REACT_APP_API_URL + '/user/forgot/' + data.userName, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                props.responseStatus(response.message)
            })
    }

    const onRestore = (data) => {
        const requestData = {
            confirmPassword: data.confirmPassword,
            password: data.password,
            token: authCtx.restoreToken,
        }
        fetch(process.env.REACT_APP_API_URL + '/user/resetpassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                props.responseStatus(response.message)
                if (response.status) {
                    authCtx.restoreModeHandler(false)
                    window.history.pushState(
                        'clean',
                        'Thinkfully',
                        window.location.origin
                    )
                }
            })
    }

    return (
        <Hexagon>
            <h2 className={classes.guestTitle}> Forgot your password? Or need to reset? </h2>
            {authCtx.restoreMode && (
                <Form
                    className={classes.form}
                    onSubmit={handleSubmit(onRestore)}
                >
                    <Form.Field className={classes.control}>
                        <input
                            placeholder="Password"
                            type="password"
                            {...register('password', {
                                required: true,
                            })}
                        />
                        {errors.password && (
                            <p className={classes.message}>
                                Please check the Password
                            </p>
                        )}
                    </Form.Field>

                    <Form.Field className={classes.control}>
                        <input
                            placeholder="Password"
                            type="password"
                            {...register('confirmPassword', {
                                required: true,
                            })}
                        />
                        {errors.confirmPassword && (
                            <p className={classes.message}>
                                Please check to match Password
                            </p>
                        )}
                    </Form.Field>

                    <Button className={classes.btn} type="submit">
                        Submit
                    </Button>
                </Form>
            )}
            {!authCtx.restoreMode && (
                <Form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Form.Field className={classes.control}>
                        <input
                            placeholder="Email"
                            type="email"
                            {...register('userName', {
                                required: true,
                                pattern:
                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                        />
                        {errors.userName && (
                            <p className={classes.message}>
                                Please check the Email
                            </p>
                        )}
                        <Button className={classes.btn} type="submit">
                            Submit
                        </Button>
                    </Form.Field>
                </Form>
            )}
        </Hexagon>
    )
}

RestorePassword.propTypes = {
    responseStatus: PropTypes.func,
}

export default RestorePassword
