import React, { useContext } from 'react'
import PopupContext from '../../store/popup/popup-context'
import Close from '../Home/Close/Close'
import classes from './Popup.module.css'

export default function Popup() {
    const ctx = useContext(PopupContext)
    const popupProps = ctx.popupProps
    const setPopupProps = ctx.setPopupProps
    return popupProps.open ? (
        <div
            className={`${classes.popup}`}
            onClick={() => setPopupProps({ open: false })}
        >
            <div className={classes.overlay} />
            <div className={`${classes.popupWithClose}`}>
                {popupProps.withClose && (
                    <Close onClick={() => setPopupProps({ open: false })} />
                )}
                <div className={`${classes.hexagon}`}>
                    <div className={classes.box}>
                        <span className={classes.message}>
                            {popupProps.message}
                        </span>
                        <div className={classes.popupButtonsWrapper}>
                            {popupProps.onOk && (
                                <button
                                    className={classes.modalBtn}
                                    onClick={() => popupProps.onOk()}
                                >
                                    Continue
                                </button>
                            )}
                            {popupProps.onCancel && (
                                <button
                                    className={classes.modalBtn}
                                    onClick={() => popupProps.onCancel()}
                                >
                                    Cancel
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : null
}
