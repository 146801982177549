import React, {
    useContext,
    useEffect,
    useState,
    useRef,
    useLayoutEffect,
} from 'react'
import HexagonBoard from './HexagonBoard/HexagonBoard'
import Sidebar from './Sidebar/Sidebar'
import HexContext from '../../store/hex/hex-context'
import AuthContext from '../../store/auth/auth-context'
import classes from './Home.module.css'
import TopBar from './TopBar/TopBar'
import Timeline from './Timeline/Timeline'
import Hexagon from '../Login/Hexagon/Hexagon'
import Close from './Close/Close'
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt'
import { cloneDeep } from 'lodash'
import { record } from 'rrweb'
import Popup from '../Popup'
import SharePopup from '../SharePopup'
import AddBoardPopup from '../AddBoardPopup'
import BoardContext from '../../store/board/board-context'
import BoardTitlePopup from '../BoardTitlePopup'



let eventsMatrix = [[]]
let stopFn

const Home = () => {
    const ctx = useContext(HexContext)
    const authCtx = useContext(AuthContext)
    const boardCtx = useContext(BoardContext)
    const [limitPopupActive, setLimitPopupActive] = useState(false)
    const [autoSaveEvents, setAutoSaveEvents] = useState(false)
    const [hexagonData, setHexagonData] = useState({})
    const hexagonList = boardCtx.hexagonList

    const [playbackVersion, setPlaybackVersion] = useState(hexagonList)
    const [trackingStatus, setTrackingStatus] = useState(false)
    const [activityId, setActivityId] = useState(null)
    const [forceCheckout, setForceCheckout] = useState(false)
    const [appLoading, setAppLoading] = useState(true)
    const [panningGrid, setPanningGrid] = useState(false)

    useCallbackPrompt(ctx.changed)
    const viewRef = useRef(null)
    const homeRef = useRef(null)
    const gridRef = useRef(null)
    useEffect(() => {
        gridRef.current.addEventListener('touchmove', () =>
            setPanningGrid(true)
        )
        gridRef.current.addEventListener('touchend', () =>
            setPanningGrid(false)
        )
        document.addEventListener('focusout', (e) => {
            if (e.relatedTarget === null) {
                // ctx.setFocusedHex(null)
                // window.scrollTo(0, 0)
            }
        })
        return () => {
            gridRef.current?.removeEventListener('touchmove', () =>
                setPanningGrid(true)
            )
            gridRef.current?.removeEventListener('touchend', () =>
                setPanningGrid(false)
            )
        }
    }, [])

    const timeline = boardCtx.timeline
    const setHexagons = boardCtx.setHexagons
    const resetCount = boardCtx.resetCount
    let presentCount = timeline.present.data
    const setAutoSave = boardCtx.setAutoSave
    const recordHandler = () => {
        eventsMatrix = [[]]
        setTrackingStatus(true)
        stopFn = record({
            emit(event, isCheckout) {
                if (isCheckout || forceCheckout) {
                    eventsMatrix.push([])
                    setForceCheckout(false)
                }
                const lastEvents = eventsMatrix[eventsMatrix.length - 1]
                lastEvents.push(event)
            },
            checkoutEveryNms: 10000,
        })
    }

    const onStopTracking = () => {
        setTrackingStatus(false)
        stopFn && stopFn()
    }

    function saveEvents() {
        const events = eventsMatrix.flat(1)
        setAutoSaveEvents(false)
        if (!events.length && events.length < 2) {
            return
        }
        const activityDuration =
            (new Date(events[events.length - 1].timestamp).getTime() -
                new Date(events[0].timestamp).getTime()) /
            1000
        if (activityDuration < 5) {
            return
        }
        const body = JSON.stringify({
            username: authCtx.userName,
            activityId,
            activities: { events },
        })
        eventsMatrix = [[]]
        setForceCheckout(true)
        fetch(`${process.env.REACT_APP_API_URL}/user/activity`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authCtx.token}`,
            },
            body,
        })
            .then((res) => {
                return res.json()
            })
            .then((res) => {
                if (res.status) {
                    setActivityId(res.activityId)
                }
            })
    }
    useEffect(() => {
        if (!trackingStatus && presentCount.length && authCtx.userName) {
            recordHandler()
        }
    }, [trackingStatus, presentCount.length, authCtx.userName])

    useEffect(() => {
        const keyList = hexagonList?.reduce((a, x) => {
            a[x.id] = x
            return a
        }, {})
        setHexagonData(keyList)

        presentCount.forEach((item) =>
            hexagonData[item.id] !== undefined ? hexagonData[item.id] : item
        )
        return () => {
            onStopTracking()
        }
    }, [])

    useEffect(() => {
        const selectedHex = presentCount[ctx.activeHex]
        if (selectedHex) {
            ctx.setCopyData({
                content: selectedHex.content,
                color: selectedHex.color,
                italic: selectedHex.italic,
                bold: selectedHex.bold,
                golden: selectedHex.golden,
            })
        }
    }, [presentCount[ctx.activeHex]])

    const formatHexagons = (boardData, update, zoom = true) => {
        if (boardData?.canvas?.length) {
            let dataOrdered = [...boardData.canvas]
            dataOrdered = dataOrdered.sort(function (a, b) {
                if (a.hexY == b.hexY) return a.hexX - b.hexX
                return a.hexY - b.hexY
            })
            const zoomId =
                dataOrdered[
                    dataOrdered.length > 2
                        ? Math.ceil(dataOrdered.length / 2)
                        : 0
                ].index
            let prevState = [...presentCount]
            if (update) {
                prevState.forEach((item) => {
                    let newObject = { ...prevState[item.index] }
                    newObject.color = false
                    newObject.content = ''
                    newObject.bold = false
                    newObject.italic = false
                    newObject.init = false
                    newObject.golden = false
                    prevState[item.index] = newObject
                })
            }
            if (zoom) {
                let zoomItem = { ...prevState[zoomId] }
                zoomItem.init = true
                prevState[zoomId] = zoomItem
            }
            if (boardData) {
                boardData.canvas.forEach((item) => {
                    let newObject = { ...prevState[item.index] }
                    newObject.color = item.color
                    newObject.content = item.content
                    newObject.golden = item.golden
                    newObject.bold = item.bold
                    newObject.italic = item.italic
                    prevState[item.index] = newObject
                })
            }
            if (!update) {
                resetCount(prevState)
            } else {
                setHexagons({ data: prevState })
            }
        }
    }
    useLayoutEffect(() => {
        if (authCtx?.boardData?.canvas) {
            formatHexagons(authCtx.boardData)
        }
    }, [authCtx.boardData])

    useEffect(() => {
        if (autoSaveEvents) {
            saveEvents()
        }
    }, [autoSaveEvents])

    useEffect(() => {
        const interval = setInterval(() => {
            setAutoSave(true)
        }, 60000)
        const eventsInterval = setInterval(() => {
            setAutoSaveEvents(true)
        }, 15000)
        const loaderTimeout = setTimeout(() => {
            setAppLoading(false)
        }, 100)
        return () => {
            clearInterval(interval)
            clearInterval(eventsInterval)
            clearTimeout(loaderTimeout)
        }
    }, [])

    const activeAddHexagonHandler = (activeHexIndex) => {
        setTimeout(() => {
            let res = [...presentCount]
            let newObject = { ...res[activeHexIndex] }
            newObject.color = '#d3d3d3'
            if (res[activeHexIndex].color === false) {
                res[activeHexIndex] = newObject
            }
            setHexagons({ data: res })
            boardCtx.setChanged(true)
        }, 200)
    }

    const contentDataHandler = (content, index) => {
        if (presentCount[index].content === content) {
            return
        }
        let res = [...presentCount]
        let newObject = { ...presentCount[index] }
        newObject.content = content
        if (!newObject.color) {
            newObject.color = '#d3d3d3'
        }
        if (
            newObject.color === '#d3d3d3' &&
            !newObject.golden &&
            !newObject.content?.length
        ) {
            ctx.enableHexItem(false)
            newObject.color = false
        }
        res[index] = newObject
        setHexagons({ data: res })
        boardCtx.setChanged(true)
    }

    const copyContentDataHandler = (index) => {
        let prevState = [...presentCount]
        let newObject = { ...prevState[index] }
        newObject.content = ctx.copyData.content
        newObject.color = ctx.copyData.color
        newObject.italic = ctx.copyData.italic
        newObject.bold = ctx.copyData.bold
        newObject.golden = ctx.copyData.golden
        prevState[index] = newObject
        setHexagons({ data: prevState })
        boardCtx.setChanged(true)
        ctx.disableModes()
    }

    const swapHexagons = (index, index2) => {
        let prevState = [...presentCount]
        let newObject = { ...prevState[index] }
        let newObject2 = { ...prevState[index2] }

        newObject.color = presentCount[index2].color
        newObject.content = presentCount[index2].content
        newObject.italic = presentCount[index2].italic
        newObject.bold = presentCount[index2].bold
        newObject.golden = presentCount[index2].golden

        newObject2.color = presentCount[index].color
        newObject2.content = presentCount[index].content
        newObject2.italic = presentCount[index].italic
        newObject2.bold = presentCount[index].bold
        newObject2.golden = presentCount[index].golden

        prevState[index] = newObject
        prevState[index2] = newObject2

        setHexagons({ data: prevState })
        boardCtx.setChanged(true)
    }

    const multiCopyHandler = (index, posX, posY) => {
        const selectedData = presentCount.filter((item, index) =>
            ctx.selectedHexagons.includes(index)
        )
        if (selectedData.length === 0) return
        const dataOrdered = selectedData.sort((a, b) => {
            if (a.hexY > b.hexY) {
                return 1
            } else if (a.hexY === b.hexY) {
                return a.hexX > b.hexX ? 1 : -1
            } else {
                return -1
            }
        })
        const startX = dataOrdered[0].hexX
        const startY = dataOrdered[0].hexY

        const arrayPositions = dataOrdered.map((item) => {
            let newPosX = posX + item.hexX - startX
            let newPosY = posY + item.hexY - startY
            if (startY % 2 !== posY % 2) {
                if (startY % 2 !== 0 && item.hexY % 2 === 0) {
                    newPosX = posX + item.hexX - startX - 1
                } else if (startY % 2 === 0 && item.hexY % 2 !== 0) {
                    newPosX = posX + item.hexX - startX + 1
                }
            } else {
                newPosX = posX + item.hexX - startX
            }
            return {
                ...item,
                newPos: newPosX + 'key' + newPosY,
            }
        })
        let prevState = cloneDeep(presentCount)
        arrayPositions.forEach((item) => {
            const newItem = prevState.find((prev) => prev.id === item.newPos)
            if (newItem !== undefined) {
                let newObject = { ...prevState[newItem.index] }
                newObject.content = item.content
                newObject.color = item.color
                newObject.bold = item.bold
                newObject.italic = item.italic
                newObject.golden = item.golden
                prevState[newItem.index] = newObject
            }
        })
        ctx.clearSelected()
        setHexagons({ data: prevState })
        boardCtx.setChanged(true)
        ctx.disableModes()
    }

    const clearDataHandler = () => {
        authCtx.boardTitleHandler('')
        const prevState = presentCount.map((item) => ({
            ...item,
            color: false,
            content: '',
            bold: false,
            italic: false,
            init: false,
            golden: false,
        }))
        ctx.enableHexItem(false)
        resetCount(prevState)
    }

    const replaceHandler = (version, prevVersion) => {
        let prevState = cloneDeep(presentCount)
        prevState.map((hex) => {
            if (version.some((versionHex) => versionHex.index === hex.index)) {
                const versionHex = version.find(
                    (versionHex) => versionHex.index === hex.index
                )
                hex.color = versionHex.color
                hex.content = versionHex.content
                hex.bold = versionHex.bold
                hex.italic = versionHex.italic
                hex.init = versionHex.init
                hex.golden = versionHex.golden
            } else {
                hex.color = false
                hex.content = false
                hex.italic = false
                hex.init = false
                hex.golden = false
            }
            return { ...hex }
        })
        let currentIndex
        if (prevVersion?.length && version?.length) {
            let results = version.filter(
                (item1) =>
                    !prevVersion.some(
                        (item2) =>
                            JSON.stringify(item1) === JSON.stringify(item2)
                    )
            )
            results = [
                ...results,
                ...prevVersion.filter(
                    (item1) =>
                        !version.some(
                            (item2) =>
                                JSON.stringify(item1) === JSON.stringify(item2)
                        )
                ),
            ]
            currentIndex = results[0]?.index
        } else if (version?.length) {
            currentIndex = version[version.length - 1].index
        } else if (prevVersion?.length && !version?.length) {
            currentIndex = prevVersion[prevVersion.length - 1].index
        }
        if (currentIndex && prevState[currentIndex]) {
            prevState[currentIndex].init = true
        }
        setPlaybackVersion(prevState)
    }

    const backToCurrentVersionHandler = () => {
        ctx.disablePlayBackMode()
    }

    const deleteBoard = (id) => {
        if (authCtx.boardName === id) {
            authCtx.boardNameHandler(false)
            clearDataHandler()
        }
        authCtx.deleteBoardHandler(id, () => {
            if (authCtx.boardName === id) {
                ctx.setBoardPopupProps({
                    active: true,
                    message:
                        'Your canvas has been deleted. Please create a new canvas or select a previous canvas from the main menu',
                })
                boardCtx.setChanged(false)
            }
        })
        boardCtx.setDeleteBoardPopupProps({ open: false })
    }

    useEffect(() => {
        if (authCtx.boards) {
            if (!authCtx.boards.length) {
                ctx.setBoardPopupProps({
                    active: true,
                    message: `You don't have a canvas yet, please create a new canvas from the main menu`,
                })
            }
        }
    }, [authCtx.boards])

    useEffect(() => {
        if (authCtx.isNewUser) {
            boardCtx.setAddBoardPopupProps({ open: true })
        }
    }, [authCtx.isNewUser])

    return (
        <div
            ref={homeRef}
            className={`${classes.home} ${classes[authCtx.menuToggle]} ${
                authCtx.shareMode && classes.share
            }`}
        >
            {(authCtx.dataLoading || appLoading) && !authCtx.shareMode && (
                <div className={classes.popup}>
                    <div className={classes.overlay} />
                    <div className={classes.loaderText}>Loading...</div>
                </div>
            )}
            {authCtx.boardName && (
                <span id={`boardName=${authCtx.boardName}`} />
            )}
            {authCtx.shareMode && authCtx.boardError.length > 0 && (
                <div className={classes.popup}>
                    <div className={classes.overlay} />
                    <div className={classes.link}>{authCtx.boardError}</div>
                </div>
            )}
            {ctx.boardPopupProps.active && (
                <div className={classes.boardPopup}>
                    <div className={classes.overlay} />
                    <div className={classes.link}>
                        {ctx.boardPopupProps.message || ''}
                    </div>
                </div>
            )}
            {boardCtx.savePopupProps.open && (
                <div className={classes.popup}>
                    <div className={classes.overlay} />
                    <div className={classes.link}>
                        <button
                            onClick={() =>
                                boardCtx.setSavePopupProps({ open: false })
                            }
                            className={classes.close}
                        >
                            X
                        </button>
                        {boardCtx.savePopupProps.message}
                    </div>
                </div>
            )}
            <BoardTitlePopup />
            {limitPopupActive && (
                <div
                    onClick={() => setLimitPopupActive(false)}
                    className={`${classes.popup}`}
                >
                    <div className={classes.overlay} />
                    <div className={`${classes.popupWithClose}`}>
                        <Close onClick={() => setLimitPopupActive(false)} />
                        <Hexagon popup={'popup'}>
                        You have reached the text length limit.
                        </Hexagon>
                    </div>
                </div>
            )}

            {boardCtx.deleteBoardPopupProps.open && (
                <div className={`${classes.popup}`}>
                    <div className={classes.overlay} />
                    <div className={`${classes.popupWithClose}`}>
                        <Hexagon popup={'popup'}>
                            Are you sure you want to delete this canvas?
                            <br />
                            <div className={classes.PopupButtonsWrapper}>
                                <button
                                    className={classes.modalBtn}
                                    onClick={() =>
                                        deleteBoard(
                                            boardCtx.deleteBoardPopupProps.id
                                        )
                                    }
                                >
                                    Yes
                                </button>
                                <button
                                    className={classes.modalBtn}
                                    onClick={() =>
                                        boardCtx.setDeleteBoardPopupProps({
                                            open: false,
                                        })
                                    }
                                >
                                    No
                                </button>
                            </div>
                        </Hexagon>
                    </div>
                </div>
            )}
            <TopBar
                setHexagons={setHexagons}
                presentCount={presentCount}
                setChanged={boardCtx.setChanged}
                limitPopupHandler={setLimitPopupActive}
            />
            {presentCount.length && (
                <HexagonBoard
                    ref={{
                        ref1: viewRef,
                        ref2: gridRef,
                    }}
                    copyContentDataHandler={copyContentDataHandler}
                    multiCopyHandler={multiCopyHandler}
                    contentHandler={contentDataHandler}
                    hexagons={ctx.playBackMode ? playbackVersion : presentCount}
                    setActive={activeAddHexagonHandler}
                    swapHexagons={swapHexagons}
                    panningGrid={panningGrid}
                />
            )}
            <Popup />
            <SharePopup />
            <AddBoardPopup />
            {authCtx.isLoggedIn && !authCtx.shareMode && <Sidebar />}
            {ctx.playBackMode && (
                <Timeline
                    replaceHandler={replaceHandler}
                    backToCurrentVersionHandler={backToCurrentVersionHandler}
                />
            )}
            
        </div>
        
    )
}

export default Home
