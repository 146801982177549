import React, { useContext, useState } from 'react'

import classes from './ResetScreen.module.css'
import Hexagon from '../Hexagon/Hexagon'
import { useForm } from 'react-hook-form'
import { Form, Button } from 'semantic-ui-react'
import AuthContext from '../../../store/auth/auth-context'
import LinkVideos from '../LinkVideos/LinkVideos';
import useDetectKeyboardOpen from "use-detect-keyboard-open";


const RestorePassword = () => {
    const authCtx = useContext(AuthContext)
    const [loading, setLoading] = useState(false)
    const [responseStatus, setResponseStatus] = useState('')
    const isKeyboardOpen = useDetectKeyboardOpen();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()
    const onSubmit = async (data) => {
        setLoading(true)
        await fetch(
            process.env.REACT_APP_API_URL + '/user/forgot/' + data.userName,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                setLoading(false)
                setResponseStatus(response.message)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const onRestore = (data) => {
        const requestData = {
            confirmPassword: data.confirmPassword,
            password: data.password,
            token: authCtx.restoreToken,
        }
        fetch(process.env.REACT_APP_API_URL + '/user/resetpassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                setResponseStatus(response.message)
                if (response.status) {
                    authCtx.restoreModeHandler(false)
                    window.history.pushState(
                        'clean',
                        'Thinkfully',
                        window.location.origin
                    )
                    authCtx.stageHandler('login')
                }
            })
    }

    return (
        <div className={classes.login}>
            {loading && (
                <div className={classes.popup}>
                    <div className={classes.overlay} />
                    <div className={classes.popupMessage}>Loading...</div>
                </div>
            )}
            <div className="container">
                <h4 className={classes.responseStatus}>{responseStatus}</h4>
                <div className="row justify-center">
                    <div className="col-6">
                        <Hexagon>
                            <h2 className={classes.guestTitle}>
                            Forgot your password? Or need to reset?
                            </h2>
                            {authCtx.restoreMode && (
                                <h4 className={classes.guestTitle}>
                                    Please enter a new password below
                                </h4>
                            )}
                            {authCtx.restoreMode && (
                                <Form
                                    className={classes.form}
                                    onSubmit={handleSubmit(onRestore)}
                                >
                                    <Form.Field className={classes.control}>
                                        <input
                                            placeholder="Password"
                                            type="password"
                                            {...register('password', {
                                                required: true,
                                                pattern:
                                                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                                            })}
                                        />
                                        {errors.password && (
                                            <p className={classes.message}>
                                                Please check the Password
                                            </p>
                                        )}
                                    </Form.Field>

                                    <Form.Field className={classes.control}>
                                        <input
                                            placeholder="Password"
                                            type="password"
                                            {...register('confirmPassword', {
                                                required: true,
                                                pattern:
                                                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                                            })}
                                        />
                                        {errors.confirmPassword && (
                                            <p className={classes.message}>
                                                Please check to match Password
                                            </p>
                                        )}
                                    </Form.Field>

                                    <Button
                                        className={classes.btn}
                                        type="submit"
                                    >
                                        Submit
                                    </Button>
                                </Form>
                            )}
                            {!authCtx.restoreMode && (
                                <Form
                                    className={classes.form}
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <Form.Field className={classes.control}>
                                        <input
                                            placeholder="Email"
                                            type="email"
                                            {...register('userName', {
                                                required: true,
                                                pattern:
                                                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            })}
                                        />
                                        {errors.userName && (
                                            <p className={classes.message}>
                                                Please check the Email
                                            </p>
                                        )}
                                        <Button
                                            className={classes.btn}
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </Form.Field>
                                </Form>
                            )}
                        </Hexagon>
                    </div>
                </div>
            </div>
            <div className={` ${isKeyboardOpen ? `${classes.linkTitleKeyboard}` : `${classes.linkTitle}`}`} >
                <LinkVideos />
            </div>
        </div>
    )
}

export default RestorePassword
