import React, { useContext } from 'react'
import classes from './AddNewBoard.module.css'
import BoardContext from '../../../../store/board/board-context'
import PopupContext from '../../../../store/popup/popup-context'

const AddNewBoard = () => {
    const boardCtx = useContext(BoardContext)
    const popupCtx = useContext(PopupContext)
    return (
        <div
            onClick={() => {
                if (!boardCtx.changed) {
                    boardCtx.setAddBoardPopupProps({
                        open: true,
                    })
                } else {
                    popupCtx.setPopupProps({
                        open: true,
                        message:
                            'You have unsaved changes, would you like to continue without saving? Any unsaved changes will be lost',
                        onCancel: () => {
                            popupCtx.setPopupProps({
                                open: false,
                            })
                        },
                        onOk: () => {
                            popupCtx.setPopupProps({
                                open: false,
                            })
                            boardCtx.setAddBoardPopupProps({
                                open: true,
                            })
                        },
                    })
                }
            }}
            className={classes.picker}
            id="new_canvas"
        >
            <span className={classes.button}>
                <img
                 draggable="false"
                    className={classes.image}
                    src={require('../../../../assets/add-new.png')}
                />
                <span className={classes.tooltip}>New Canvas</span>
            </span>
        </div>
    )
}

export default AddNewBoard
