import React from 'react'

const BoardContext = React.createContext({
    saving: {},
    versions: [],
    timeline: {},
    shareData: {},
    undoCount: [],
    redoCount: [],
    changed: false,
    savechanged: false,
    autoSave: false,
    zoomedHexId: null,
    savePopupProps: {},
    sharePopupProps: {},
    shareDataError: null,
    addBoardPopupProps: {},
    shareDataLoading: false,
    boardTitlePopupProps: {},
    deleteBoardPopupProps: {},
    reset: () => {},
    doReset: () => {},
    setSaving: () => {},
    resetCount: () => {},
    setChanged: () => {},
    setSaveChanged: () => {},
    saveHandler: () => {},
    setAutoSave: () => {},
    setHexagons: () => {},
    getShareData: () => {},
    setZoomedHexId: () => {},
    operationHandler: () => {},
    setSavePopupProps: () => {},
    setSharePopupProps: () => {},
    setAddBoardPopupProps: () => {},
    setBoardTitlePopupProps: () => {},
    setDeleteBoardPopupProps: () => {},
})

export default BoardContext
