import { useCallback, useEffect, useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { UNSAFE_NavigationContext } from 'react-router-dom'

export function useBlocker(blocker, when = true) {
    const navigator = useContext(UNSAFE_NavigationContext).navigator

    useEffect(() => {
        if (!when) return

        const unblock = navigator.block((tx) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock()
                    tx.retry()
                },
            }

            blocker(autoUnblockingTx)
        })

        return unblock
    }, [navigator, blocker, when])
}

export function useCallbackPrompt(when) {
    const navigate = useNavigate()
    const location = useLocation()
    const [showPrompt, setShowPrompt] = useState(false)
    const [lastLocation, setLastLocation] = useState(null)
    const [confirmedNavigation, setConfirmedNavigation] = useState(false)

    const cancelNavigation = useCallback(() => {
        setShowPrompt(false)
    }, [])

    // handle blocking when user click on another route prompt will be shown
    const handleBlockedNavigation = useCallback(
        (nextLocation) => {
            // in if condition we are checking next location and current location are equals or not
            if (
                !confirmedNavigation &&
                nextLocation.location.pathname !== location.pathname
            ) {
                setShowPrompt(true)
                setLastLocation(nextLocation)
                return false
            }
            return true
        },
        [confirmedNavigation]
    )

    const confirmNavigation = useCallback(() => {
        setShowPrompt(false)
        setConfirmedNavigation(true)
    }, [])

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.location.pathname)
        }
    }, [confirmedNavigation, lastLocation])

    useBlocker(handleBlockedNavigation, when)

    return [showPrompt, confirmNavigation, cancelNavigation]
}
