import React from 'react'

const HexagonBrowser = () => {
  
  if( sessionStorage.getItem("browser") === null ){    
    const userAgent = navigator.userAgent    
    let ios = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i) || !!userAgent.match(/iPod/i)
    let safari = userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') < 0
    let chrome = userAgent.indexOf('Chrome')
    let firefox = userAgent.indexOf('Firefox')
    let data = {
        safari:safari,
        chrome:chrome,
        firefox:firefox,
        ios:ios
    };
    
    sessionStorage.setItem("browser", JSON.stringify(data));
    
  }
  

  return (
    <div>      
    </div>
  )
}

export default HexagonBrowser
