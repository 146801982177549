import React from 'react'

const AdminContext = React.createContext({
    getActivities: () => {},
    getActivityContent: () => {},
    getUsers: () => {},
    reset: () => {},
    users: null,
    activityData: {},
    activityContent: {},
    activityDataError: null,
    activityDataLoading: false,
})

export default AdminContext
