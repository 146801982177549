import React, { useContext, useEffect, useState } from 'react'
import classes from './TopBar.module.css'
import AuthContext from '../../../store/auth/auth-context'
import PropTypes from 'prop-types'
import useViewPort from '../../../hooks/useViewPort'

const TopBar = (props) => {
    const authCtx = useContext(AuthContext)
    const [message, setMessage] = useState('')
    const [editable, setEditable] = useState(true)
    const [currentKey, setCurrentKey] = useState('')
    const [placeholder,setPlaceholder]=useState('')
    const [mobile] = useViewPort()

    useEffect(()=>{
        if(!mobile){
            setPlaceholder("What's on your mind? Enter your question or text here...")
        }
        else{
            setPlaceholder("What's on your mind? Enter here...")
        }
    },[mobile])
  


    useEffect(() => {
        setMessage(authCtx.boardMessage)
    }, [authCtx.boardMessage])

    useEffect(() => {
        authCtx.shareMode && setEditable(false)
    }, [authCtx.shareMode])

    const maxLength = 60
    const setContent = (e) => {
        const currentContent = e.target.value
        const backSpaceEvent =
            currentKey === 'delete' && currentKey === 'backspace'
        if (currentContent.length >= maxLength && !backSpaceEvent) {
            props.limitPopupHandler(true)
            e.target.blur()
            return
        }
        setMessage(currentContent)
    }

    const handleBlur = () => {
        authCtx.boardMessageHandler(message)
        props.setHexagons({ data: props.presentCount, boardMessage: message })
        props.setChanged(true)
    }
    return (
        <div
            className={`${classes.navbar} ${classes[authCtx.menuToggle]} ${
                authCtx.shareMode && classes.share
            }`}
        >
            <input
                className={`${classes.content}`}
                disabled={!editable}
                onChange={setContent}
                onBlur={handleBlur}
                onKeyDown={(e) => setCurrentKey(e.key.toLowerCase())}
                value={message !== 'false' && message !== false ? message : ''}
                placeholder={
                    editable ? placeholder : ''
                }
            />
        </div>
    )
}

TopBar.propTypes = {
    setChanged: PropTypes.func,
    setHexagons: PropTypes.func,
    presentCount: PropTypes.array,
    limitPopupHandler: PropTypes.func,
}

export default TopBar
