import React, { useContext, useEffect, useState } from 'react'
import useViewPort from '../../../hooks/useViewPort'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'
/* eslint no-unused-vars: 0 */ // --> OFF
// Import Swiper React components
import { Navigation } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import PropTypes from 'prop-types'
import classes from './Sidebar.module.css'
import AccordionItem from '../AccordionItem/AccordionItem'
import { Accordion, Tab } from 'semantic-ui-react'
// import Boards from '../AddHex/Boards'
import ColorItem from '../ColorPicker/ColorItem/ColorItem'
import HexContext from '../../../store/hex/hex-context'
import BoardItem from '../AddHex/BoardItem/BoardItem'
import AuthContext from '../../../store/auth/auth-context'
import AddNewBoard from '../AddHex/AddNewBoard/AddNewBoard'
import SidebarItem from './SidebarItem/SidebarItem'
import PopupContext from '../../../store/popup/popup-context'
import BoardContext from '../../../store/board/board-context'
import Timer from '../Timer/Timer'
import { cloneDeep } from 'lodash'
// import useViewPort from '../../../hooks/useViewPort'

const colorTable = [
    { color: '#0ddd92', hex: '#0ddd92', label: 'green' },
    { color: '#cc99f8', hex: '#cc99f8', label: 'purple' },
    { color: '#fe6467', hex: '#fe6467', label: 'red' },
    { color: '#ffd508', hex: '#ffd508', label: 'gold' },
    { color: '#B8F479', hex: '#B8F479', label: 'light_green' },
    { color: '#57fbe8', hex: '#57fbe8', label: 'aquamarine' },
    { color: '#ffffff', hex: '#ffffff', label: 'white' },
    { color: '#fb9657', hex: '#fb9657', label: 'orange' },
    { color: '#8996FC', hex: '#8996FC', label: 'violet' },
    { color: '#fb57bc', hex: '#fb57bc', label: 'pink' },
    { color: '#C7CBCE', hex: '#C7CBCE', label: 'grey' },
    { color: '#57BCFB', hex: '#57BCFB', label: 'blue' },
]

const Sidebar = (props) => {
    const authCtx = useContext(AuthContext)
    const hexCtx = useContext(HexContext)
    const popupCtx = useContext(PopupContext)
    const boardCtx = useContext(BoardContext)
    const adminCtx = useContext(BoardContext)
    const isKeyboardOpen = useDetectKeyboardOpen()

    const [boards, setBoards] = useState([])
    const [sectionId, setSectionId] = useState(null)
    const [mobile, landscape, width] = useViewPort()
    const timeline = boardCtx.timeline

    useEffect(() => {
        setBoards(authCtx.boards || [])
    }, [authCtx.boards])

    useEffect(() => {
        hexCtx.colorPickerHandler(false)
    }, [landscape])

    const toggleBoard = () => {
        if (!authCtx.guestMode) {
            hexCtx.addHexModeHandler(!hexCtx.addHexMode)
        } else {
            authCtx.stageHandler('signup')
        }
    }
    const toggleCanvases = () => {
        if (!hexCtx.addHexMode) {
            setSectionId('menu_canvas')
        }
        hexCtx.addHexModeHandler(!hexCtx.addHexMode)
    }
    const toggleColourPicker = (e) => {
        hexCtx.colorPickerHandler(!hexCtx.colorPicker)
        setSectionId('menu_design')
    }
    const logOutHandler = () => {
        boardCtx.reset()
        hexCtx.reset()
        popupCtx.reset()
        adminCtx.reset()
        authCtx.onLogout()
    }
    const toggleSection = (id) => {
        if (hexCtx.showTimer) {
            hexCtx.showTimerHandler(!hexCtx.showTimer)
        }
        if (hexCtx.colorPicker) {
            hexCtx.colorPickerHandler(!hexCtx.colorPicker)
        }
        if (hexCtx.addHexMode) {
            hexCtx.addHexModeHandler(!hexCtx.addHexMode)
        }
        if (hexCtx.playBackMode) {
            hexCtx.disableModes()
        }
        if (sectionId === id) {
            setSectionId(null)
        } else {
            setSectionId(id)
        }
    }
    const removeDataHandler = () => {
        const presentCount = boardCtx.timeline.present.data
        let prevState = [...presentCount]
        if (hexCtx.selectMode) {
            const selectedHexagons = hexCtx.selectedHexagons
            selectedHexagons.forEach((item) => {
                prevState[item] = {
                    ...prevState[item],
                    content: false,
                    color: false,
                    golden: false,
                }
            })
            boardCtx.setHexagons({ data: prevState })
            boardCtx.setChanged(true)
            hexCtx.enableSelectMode()
            return
        }
        let newObject = { ...presentCount[hexCtx.activeHex] }
        newObject.content = false
        newObject.color = false
        newObject.golden = false
        newObject.italic = false
        newObject.bold = false
        prevState[hexCtx.activeHex] = newObject
        boardCtx.setHexagons({ data: prevState })
        boardCtx.setChanged(true)
        hexCtx.enableHexItem(null)
    }
    const zoomToHex = (version, prevVersion) => {
        let currentIndex = 0
        if (prevVersion?.length && version?.length) {
            const results = version.filter(
                (item1) =>
                    !prevVersion.some(
                        (item2) =>
                            JSON.stringify(item1) === JSON.stringify(item2)
                    )
            )
            currentIndex = results[0]?.index
        } else if (version?.length) {
            currentIndex = version[version.length - 1].index
        }
        boardCtx.setZoomedHexId(currentIndex)
        return currentIndex
    }
    function isInViewport(element) {
        const rect = element?.getBoundingClientRect()
        return (
            rect?.top >= 0 &&
            rect?.left >= 0 &&
            rect?.bottom <=
                (window?.innerHeight ||
                    document?.documentElement?.clientHeight) &&
            rect?.right <=
                (window?.innerWidth || document?.documentElement?.clientWidth)
        )
    }
    const undoHandler = () => {
        if (timeline.past.length <= 1) {
            return
        } else {
            boardCtx.setSavePopupProps({
                open: true,
                message: 'Loading...',
            })
            console.log(
                'opened undo-redo loading popup at',
                new Date().getTime()
            )
        }
        const presentCount = timeline.past[timeline.past.length - 1].data
        let newPresent = [...presentCount]
        hexCtx.enableHexItem(false)
        const index = zoomToHex(
            timeline.past[timeline.past.length - 1].data,
            timeline.present.data
        )
        const hexagonItem = document.getElementById(`hexagonItem${index}`)
        if (isInViewport(hexagonItem)) {
            boardCtx.undoCount()
            boardCtx.setChanged(true)
        } else {
            setTimeout(() => {
                boardCtx.undoCount()
                boardCtx.setChanged(true)
            }, 500)
        }

        //set new present for saving
        boardCtx.setHexagons({ data: newPresent, undoRedoVersion: true })
    }
    const redoHandler = () => {
        if (timeline.future.length < 1) {
            return
        } else {
            boardCtx.setSavePopupProps({
                open: true,
                message: 'Loading...',
            })
            console.log(
                'opened undo-redo loading popup at',
                new Date().getTime()
            )
        }
        const futureVersions = boardCtx.timeline.future
        let futureBoardCopy = [...futureVersions]
        const newPresent = futureBoardCopy[0].data
        hexCtx.enableHexItem(false)
        const index = zoomToHex(futureBoardCopy[0].data, timeline.present.data)
        const hexagonItem = document.getElementById(`hexagonItem${index}`)
        if (isInViewport(hexagonItem)) {
            boardCtx.redoCount()
            boardCtx.setChanged(true)
        } else {
            setTimeout(() => {
                boardCtx.redoCount()
                boardCtx.setChanged(true)
            }, 500)
        }

        //set new present for saving
        boardCtx.setHexagons({ data: newPresent, undoRedoVersion: true })

        // setTimeout(() => {
        //     boardCtx.setSavePopupProps({
        //         open:false
        //     });
        // }, 500);

        // boardCtx.setSavePopupProps({
        //     open:false
        // });
    }
    const boldWeightHandler = () => {
        const presentCount = boardCtx.timeline.present.data
        let prevState = [...presentCount]
        boardCtx.setChanged(true)
        if (!hexCtx.selectMode) {
            let newObject = { ...prevState[hexCtx.activeHex] }
            const state = newObject.bold
            newObject.bold = !state
            prevState[hexCtx.activeHex] = newObject
            boardCtx.setHexagons({ data: prevState })
            return
        }
        const selected = prevState.filter((item, index) =>
            hexCtx.selectedHexagons.includes(index)
        )
        const type = selected.some((obj) => obj.bold)
        const changedWeight = prevState.map((item, index) =>
            hexCtx.selectedHexagons.includes(index)
                ? { ...item, bold: !type }
                : item
        )
        boardCtx.setHexagons({ data: changedWeight })
        hexCtx.enableSelectMode()
        hexCtx.enableHexItem(null)
    }
    const italicStyleHandler = () => {
        const presentCount = boardCtx.timeline.present.data
        let prevState = [...presentCount]
        if (!hexCtx.selectMode) {
            let newObject = { ...prevState[hexCtx.activeHex] }
            const state = newObject.italic
            newObject.italic = !state
            prevState[hexCtx.activeHex] = newObject
            boardCtx.setHexagons({ data: prevState })
            return
        }
        const selected = prevState.filter((item, index) =>
            hexCtx.selectedHexagons.includes(index)
        )
        const type = selected.some((obj) => obj.italic)
        const changedStyle = prevState.map((item, index) =>
            hexCtx.selectedHexagons.includes(index)
                ? { ...item, italic: !type }
                : item
        )
        boardCtx.setHexagons({ data: changedStyle })
        boardCtx.setChanged(true)
        hexCtx.enableSelectMode()
        hexCtx.enableHexItem(null)
    }
    const enableGoldenGem = () => {
        const presentCount = boardCtx.timeline.present.data
        let prevState = [...presentCount]
        if (!hexCtx.selectMode) {
            let newObject = { ...prevState[hexCtx.activeHex] }
            const state = newObject.golden
            newObject.golden = !state
            prevState[hexCtx.activeHex] = newObject
            boardCtx.setHexagons({ data: prevState })
            return
        }
        const selected = prevState.filter((item, index) =>
            hexCtx.selectedHexagons.includes(index)
        )
        const type = selected.some((obj) => obj.golden)
        const changedStyle = prevState.map((item, index) =>
            hexCtx.selectedHexagons.includes(index)
                ? { ...item, golden: !type }
                : item
        )
        boardCtx.setHexagons({ data: changedStyle })
        boardCtx.setChanged(true)
        hexCtx.enableSelectMode()
        hexCtx.enableHexItem(null)
    }
    const playBackHandler = () => {
        if (!authCtx.isOnline) {
            popupCtx.setPopupProps({
                open: true,
                withClose: true,
                message:
                    'Looks like you are offline. You can continue to make updates but the ‘Play’ features will be unavailable at this time. Your canvas will save when next online.',
            })
        } else {
            boardCtx.setSavePopupProps({
                open: true,
                message: 'Loading...',
            });

            boardCtx.saveHandler(boardCtx.versions, true, () => {
                if (!hexCtx.playBackMode) {
                    fetch(
                        process.env.REACT_APP_API_URL +
                            '/user/dashboard/versions/' +
                            authCtx.boardName,
                        {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${authCtx.token}`,
                            },
                        }
                    )
                        .then((response) => {
                            return response.json()
                        })
                        .then((response) => {
                            if (response) {
                                setSectionId(null)
                                authCtx.getVersionsHandler(response)                                
                            }
                        })
                        .then(() => {
                            hexCtx.enablePlayBackMode()
                            boardCtx.setSavePopupProps({
                                open: false,
                            })
                        })
                }
            });
        }
    }
    const updateTitleHandler = (id, title, onSuccess) => {
        if (id === authCtx.boardName) {
            authCtx.boardTitleHandler(title)
        }
        const data = {
            id: id,
            title,
        }
        fetch(process.env.REACT_APP_API_URL + '/user/dashboard/title', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authCtx.token}`,
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                authCtx.getBoardsHandler()
                return response.json()
            })
            .then((response) => {
                if (onSuccess) {
                    onSuccess()
                }
                if (!response.status) {
                    console.log('title change failed')
                }
            })
    }
    let boardItems = boards.map((item, index) => (
        <BoardItem
            toggleBoard={toggleBoard}
            key={item.boardName}
            name={item.title}
            setBoardId={props.setBoardId}
            order={index}
            id={item.boardName}
            image={item.previewImageUrl}
            updateTitleHandler={updateTitleHandler}
        />
    ))
    const canvasSectionItems = [
        {
            id: 'share-wrapper',
            el: (
                <SidebarItem
                    key="share"
                    id="share"
                    tooltip="Share"
                    clickHandler={() => {
                        boardCtx.setSharePopupProps({
                            open: true,
                            message: 'Loading...',
                        })
                    }}
                    img={require('../../../assets/Share.png')}
                />
            ),
        },
        {
            id: 'canvases-wrapper',
            el: (
                <SidebarItem
                    key="canvases"
                    id="canvases"
                    tooltip="Canvases"
                    clickHandler={toggleCanvases}
                    img={require('../../../assets/add-new.png')}
                />
            ),
        },
        {
            id: 'save-wrapper',
            el: (
                <SidebarItem
                    key="save"
                    id="save"
                    tooltip="Save"
                    clickHandler={() => {
                        if (
                            authCtx.boards.find(
                                (item) => item.boardName === authCtx.boardName
                            ).autoTitle
                        ) {
                            boardCtx.setBoardTitlePopupProps({ open: true })
                        } else {
                            if (sessionStorage.getItem('onlineCheck') == null) {
                                boardCtx.saveHandler(boardCtx.versions, true)
                            }
                        }
                    }}
                    img={require('../../../assets/Save.png')}
                    disabled={boardCtx.saveChanged == true}
                />
            ),
        },
        {
            id: 'copy-canvas-wrapper',
            el: (
                <SidebarItem
                    key="copy_canvas"
                    id="copy_canvas"
                    tooltip="Copy Canvas"
                    clickHandler={async () => {
                        if (authCtx.boards.length >= 10) {
                            popupCtx.setPopupProps({
                                open: true,
                                withClose: true,
                                message: `You already have the maximum amount of canvases.
                                          Please delete a canvas and try again`,
                            })
                        } else if (boardCtx.timeline.present.data.length) {
                            if (boardCtx.changed) {
                                await boardCtx.copyHandler(
                                    boardCtx.versions,
                                    true
                                )
                            }

                            if (!boardCtx.changed) {
                                authCtx.boardNameHandler(false)
                                boardCtx.setHexagons({
                                    data: boardCtx.timeline.present.data,
                                    copy: true,
                                    boardName: false,
                                })
                                hexCtx.addHexModeHandler(!hexCtx.addHexMode)
                            }
                        }
                    }}
                    img={require('../../../assets/clone-canvas.png')}
                />
            ),
        },
        {
            id: 'log-out-wrapper',
            centered: true,
            el: (
                <SidebarItem
                    key="log_out"
                    id="log_out"
                    tooltip="Log Out"
                    clickHandler={() => {
                        if (boardCtx.changed) {
                            popupCtx.setPopupProps({
                                open: true,
                                message:
                                    'You have unsaved changes, would you like to continue without saving? Any unsaved changes will be lost',
                                onOk: () => {
                                    logOutHandler()
                                },
                                onCancel: () => {
                                    popupCtx.setPopupProps({
                                        open: false,
                                    })
                                },
                            })
                        } else {
                            logOutHandler()
                        }
                    }}
                    img={require('../../../assets/log-out.png')}
                />
            ),
        },
    ]
    const designSectionItems = [
        {
            id: 'delete-wrapper',
            el: (
                <SidebarItem
                    key="delete"
                    id="delete"
                    tooltip="Delete"
                    clickHandler={removeDataHandler}
                    img={require('../../../assets/delete.png')}
                />
            ),
        },
        {
            id: 'colour-wrapper',
            el: (
                <SidebarItem
                    key="colour"
                    id="colour"
                    tooltip="Colour"
                    clickHandler={toggleColourPicker}
                    img={require('../../../assets/all_colors.png')}
                />
            ),
        },
        {
            id: 'undo-wrapper',
            el: (
                <SidebarItem
                    key="undo"
                    id="undo"
                    tooltip="Undo"
                    clickHandler={undoHandler}
                    img={require('../../../assets/Undo.png')}
                    disabled={timeline.past.length < 2}
                />
            ),
        },
        {
            id: 'redo-wrapper',
            el: (
                <SidebarItem
                    key="redo"
                    id="redo"
                    tooltip="Redo"
                    clickHandler={redoHandler}
                    img={require('../../../assets/Undo.png')}
                    className={classes.rotated}
                    disabled={timeline.future.length < 1}
                />
            ),
        },
        {
            id: 'select-wrapper',
            el: (
                <SidebarItem
                    active={hexCtx.selectMode}
                    key="multi-select"
                    id="multi-select"
                    tooltip="Select"
                    clickHandler={() => {
                        if (!hexCtx.boardPopupProps.active) {
                            if (hexCtx.selectMode === true) {
                                hexCtx.clearSelected()
                            }
                            hexCtx.enableSelectMode()
                        }
                    }}
                    img={require('../../../assets/multi-select-new.png')}
                />
            ),
        },
        {
            id: 'bold-wrapper',
            el: (
                <SidebarItem
                    key="bold"
                    id="bold"
                    tooltip="Bold"
                    clickHandler={boldWeightHandler}
                    img={require('../../../assets/bold.png')}
                />
            ),
        },
        {
            id: 'italic-wrapper',
            el: (
                <SidebarItem
                    key="italic"
                    id="italic"
                    tooltip="Italic"
                    clickHandler={italicStyleHandler}
                    img={require('../../../assets/Italic.png')}
                />
            ),
        },
        {
            id: 'copy-wrapper',
            el: (
                <SidebarItem
                    active={hexCtx.copyMode}
                    key="copy"
                    id="copy"
                    tooltip="Copy"
                    clickHandler={hexCtx.enableCopyMode}
                    img={require('../../../assets/copy.png')}
                />
            ),
        },
    ]
    const playSectionItems = [
        {
            id: 'replay-wrapper',
            el: (
                <SidebarItem
                    key="replay"
                    id="replay"
                    tooltip="Replay"
                    clickHandler={playBackHandler}
                    img={require('../../../assets/Playback_Forward.png')}
                />
            ),
        },
        {
            id: 'gem-wrapper',
            el: (
                <SidebarItem
                    key="gem"
                    id="gem"
                    tooltip="Gem"
                    clickHandler={enableGoldenGem}
                    img={require('../../../assets/golden-nugget.png')}
                />
            ),
        },
        {
            id: 'sort-wrapper',
            el: (
                <SidebarItem
                    key="sort"
                    id="sort"
                    tooltip="Sort"
                    clickHandler={() => boardCtx.operationHandler('sort')}
                    img={require('../../../assets/sort.png')}
                />
            ),
        },
        {
            id: 'shuffle-wrapper',
            el: (
                <SidebarItem
                    key="shuffle"
                    id="shuffle"
                    tooltip="Shuffle"
                    clickHandler={() => boardCtx.operationHandler('shuffle')}
                    img={require('../../../assets/shuffle.png')}
                />
            ),
        },
        {
            id: 'timer-wrapper',
            centered: true,
            el: (
                <SidebarItem
                    key="timer"
                    id="timer"
                    tooltip="Timer"
                    clickHandler={() => {
                        hexCtx.showTimerHandler(!hexCtx.showTimer)
                    }}
                    img={require('../../../assets/Timer.png')}
                />
            ),
        },
    ]
    const picker = colorTable.map((item) => (
        <ColorItem
            id={item.label}
            setActive={props.setColor}
            color={item.color}
            key={item.color}
            multiColorHandler={props.multiColorHandler}
        />
    ))
    const mobilePicker = colorTable.map((item) => (
        <SwiperSlide key={item.color}>
            <ColorItem
                id={item.label}
                setActive={props.setColor}
                color={item.color}
                key={item.color}
                multiColorHandler={props.multiColorHandler}
            />
        </SwiperSlide>
    ))
    return (
        <>
            {!mobile ? (
                <section className={classes.sidebar}>
                    <div className={classes.content}>
                        <img
                            draggable="false"
                            alt={'logo'}
                            className={classes.logo}
                            src={require('../../../assets/logo.png')}
                        />
                        <Accordion atomic={'true'}>
                            <AccordionItem
                                playBackMode={hexCtx.playBackMode}
                                id="menu_canvas"
                                isOpen={sectionId === 'menu_canvas'}
                                setIsOpen={() => toggleSection('menu_canvas')}
                                title={
                                    <div className={classes.titleWrap}>
                                        <span>Canvas</span>
                                    </div>
                                }
                            >
                                <div className={classes.itemsWrapper}>
                                    {canvasSectionItems.map((item) => {
                                        return (
                                            <React.Fragment key={item.id}>
                                                <div
                                                    className={`${
                                                        classes.itemWrapper
                                                    } ${
                                                        item.centered &&
                                                        classes.centered
                                                    } ${
                                                        sectionId ===
                                                            'menu_canvas' &&
                                                        classes.withMargin
                                                    }`}
                                                >
                                                    {item.el}
                                                </div>
                                                {item.id ===
                                                    'canvases-wrapper' && (
                                                    <>
                                                        {hexCtx.addHexMode && (
                                                            <div
                                                                className={
                                                                    classes.boardItemsWrapperUpper
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        classes.boardItemsWrapper
                                                                    }
                                                                >
                                                                    {boardItems}
                                                                    {boardItems.length <
                                                                        10 && (
                                                                        <AddNewBoard />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            </AccordionItem>
                            <AccordionItem
                                playBackMode={hexCtx.playBackMode}
                                id="menu_design"
                                isOpen={sectionId === 'menu_design'}
                                setIsOpen={() => toggleSection('menu_design')}
                                title={
                                    <div className={classes.titleWrap}>
                                        <span>Design</span>
                                    </div>
                                }
                            >
                                <div className={classes.itemsWrapper}>
                                    {designSectionItems.map((item) => {
                                        return (
                                            <React.Fragment key={item.id}>
                                                <div
                                                    key={item.id}
                                                    className={`${
                                                        classes.itemWrapper
                                                    } ${
                                                        item.centered &&
                                                        classes.centered
                                                    } ${
                                                        !sectionId ===
                                                            'menu_design' &&
                                                        classes.withMargin
                                                    }`}
                                                >
                                                    {item.el}
                                                </div>
                                                {hexCtx.colorPicker &&
                                                    item.id ===
                                                        'colour-wrapper' && (
                                                        <div className="board">
                                                            {picker}
                                                        </div>
                                                    )}
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            </AccordionItem>
                            <AccordionItem
                                playBackMode={hexCtx.playBackMode}
                                id="menu_play"
                                isOpen={sectionId === 'menu_play'}
                                setIsOpen={() => toggleSection('menu_play')}
                                title={
                                    <div className={classes.titleWrap}>
                                        <span>Play</span>
                                    </div>
                                }
                            >
                                <div className={classes.itemsWrapper}>
                                    {playSectionItems.map((item) => {
                                        return (
                                            <React.Fragment key={item.id}>
                                                <div
                                                    key={item.id}
                                                    className={`${
                                                        classes.itemWrapper
                                                    } ${
                                                        item.centered &&
                                                        classes.centered
                                                    } ${
                                                        !sectionId ===
                                                            'menu_play' &&
                                                        classes.withMargin
                                                    }`}
                                                >
                                                    {item.el}
                                                </div>
                                            </React.Fragment>
                                        )
                                    })}
                                    <div
                                        className={`time ${
                                            hexCtx.showTimer ? '' : 'hide'
                                        }`}
                                    >
                                        <Timer
                                            setPopupProps={
                                                popupCtx.setPopupProps
                                            }
                                        />
                                    </div>
                                </div>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </section>
            ) : (
                <div
                    className={`
                    ${isKeyboardOpen ? `${classes.hideMenu}` : ''}
                    ${classes.mobileSidebar} 
                    ${hexCtx.playBackMode && classes.mobileSidebarPlaybackMode} 
                    ${hexCtx.focusedHex && classes.hidden}`}
                >
                    <SidebarItem
                        clickHandler={() => toggleSection('menu_canvas')}
                        img={require('../../../assets/mobile/CanvasNew.png')}
                        tooltip="Canvas"
                        id="menu_canvas"
                        active={sectionId === 'menu_canvas'}
                        className={classes.withPadding}
                    />
                    <SidebarItem
                        clickHandler={() => toggleSection('menu_design')}
                        img={require('../../../assets/mobile/Design.png')}
                        tooltip="Design"
                        id="menu_desing"
                        active={sectionId === 'menu_design'}
                        className={classes.withPadding}
                    />
                    <SidebarItem
                        clickHandler={() => toggleSection('menu_play')}
                        img={require('../../../assets/mobile/Play.png')}
                        tooltip="Play"
                        id="menu_play"
                        active={sectionId === 'menu_play'}
                        className={classes.withPadding}
                    />
                    {sectionId && (
                        <div
                            className={`${classes.mobileSidebarContent} ${
                                hexCtx.colorPicker && classes.colorPickerWrapper
                            }`}
                        >
                            {!hexCtx.colorPicker && (
                                <Swiper
                                    modules={[Navigation]}
                                    slidesPerView={4}
                                    spaceBetween={0}
                                    navigation
                                    direction={
                                        landscape && width > 500
                                            ? 'vertical'
                                            : 'horizontal'
                                    }
                                >
                                    {sectionId === 'menu_canvas' &&
                                        canvasSectionItems.map((item) => (
                                            <SwiperSlide key={item.id}>
                                                {item.el}
                                            </SwiperSlide>
                                        ))}
                                    {sectionId === 'menu_play' &&
                                        playSectionItems.map((item) => (
                                            <SwiperSlide key={item.id}>
                                                {item.el}
                                            </SwiperSlide>
                                        ))}
                                    {sectionId === 'menu_design' &&
                                        designSectionItems.map((item) => (
                                            <SwiperSlide key={item.id}>
                                                {item.el}
                                            </SwiperSlide>
                                        ))}
                                </Swiper>
                            )}
                            {hexCtx.colorPicker && (
                                <Swiper
                                    className={classes.colorPickerMobile}
                                    modules={[Navigation]}
                                    slidesPerView={3}
                                    navigation
                                    direction={
                                        landscape && width > 500
                                            ? 'vertical'
                                            : 'horizontal'
                                    }
                                    breakpoints={{
                                        400: {
                                            slidesPerView: 4,
                                        },
                                        640: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                >
                                    {mobilePicker}
                                </Swiper>
                            )}
                            {hexCtx.addHexMode && (
                                <Swiper
                                    className={classes.boardsListMobile}
                                    modules={[Navigation]}
                                    slidesPerView={3}
                                    navigation
                                    direction={
                                        landscape && width > 500
                                            ? 'vertical'
                                            : 'horizontal'
                                    }
                                    breakpoints={{
                                        400: {
                                            slidesPerView: 3,
                                        },
                                        640: {
                                            slidesPerView: 3,
                                        },
                                    }}
                                >
                                    {boardItems.map((item) => (
                                        <SwiperSlide key={item.key}>
                                            {item}
                                        </SwiperSlide>
                                    ))}
                                    {boardItems.length < 10 && (
                                        <SwiperSlide>
                                            <AddNewBoard />
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                            )}
                        </div>
                    )}

                    <div
                        className={`timeMobile ${
                            hexCtx.showTimer ? '' : 'hideMobile'
                        }`}
                    >
                        <Timer setPopupProps={popupCtx.setPopupProps} />
                    </div>
                </div>
            )}
        </>
    )
}

Sidebar.propTypes = {
    clearDataHandler: PropTypes.func,
    saveDataHandler: PropTypes.func,
    setColor: PropTypes.func,
    multiColorHandler: PropTypes.func,
    setActive: PropTypes.func,
    disablePopup: PropTypes.func,
    forceSaveHandler: PropTypes.func,
    undoHandler: PropTypes.func,
    redoHandler: PropTypes.func,
    saveBeforePlaybackHandler: PropTypes.func,
    removeData: PropTypes.func,
    deleteAllSelected: PropTypes.func,
    shuffleDataHandler: PropTypes.func,
    backToCurrentVersionHandler: PropTypes.func,
    enableGoldenGem: PropTypes.func,
    operationHandler: PropTypes.func,
    setBold: PropTypes.func,
    enableBoldItem: PropTypes.func,
    setItalic: PropTypes.func,
    enableItalicItem: PropTypes.func,
    enablePopup: PropTypes.func,
    canUndo: PropTypes.bool,
    canRedo: PropTypes.bool,
    setChanged: PropTypes.func,
    setClonePopupActive: PropTypes.func,
    setBoardId: PropTypes.func,
    updateTitleHandler: PropTypes.func,
    sharePopupHandler: PropTypes.func,
    sharePopupActive: PropTypes.bool,
    toggleAddBoardPopup: PropTypes.func,
    setWarningPopupProps: PropTypes.func,
    changed: PropTypes.bool,
    presentCount: PropTypes.array,
    setHexagons: PropTypes.func,
    setBoardTitlePopupActive: PropTypes.func,
    manualSaveHandler: PropTypes.func,
}

export default Sidebar
