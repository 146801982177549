import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../store/auth/auth-context'
import BoardContext from '../../store/board/board-context'
import Close from '../Home/Close/Close'
import Hexagon from '../Login/Hexagon/Hexagon'
import classes from './BoardTitlePopup.module.css'

export default function BoardTitlePopup() {
    const authCtx = useContext(AuthContext)
    const boardCtx = useContext(BoardContext)

    const [canvasTitle, setCanvasTitle] = useState('')
    const popupProps = boardCtx.boardTitlePopupProps
    const setPopupProps = boardCtx.setBoardTitlePopupProps

    useEffect(() => {
        setCanvasTitle(authCtx.boardTitle)
    }, [popupProps.open])

    const updateTitleHandler = (id = authCtx.boardName, title, onSuccess) => {
        if (id === authCtx.boardName) {
            authCtx.boardTitleHandler(title)
        }
        const data = {
            id: id,
            title,
        }
        fetch(process.env.REACT_APP_API_URL + '/user/dashboard/title', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authCtx.token}`,
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                authCtx.getBoardsHandler()
                return response.json()
            })
            .then((response) => {
                if (onSuccess) {
                    onSuccess()
                }
                if (!response.status) {
                    console.log('title change failed')
                }
            })
    }
    const saveBoardHandler = () => {
        updateTitleHandler(authCtx.boardName, canvasTitle, () => {
            setPopupProps({ open: false })
            boardCtx.saveHandler(boardCtx.versions, true)
        })
    }
    return popupProps.open ? (
        <div
            onClick={() => setPopupProps({ open: false })}
            className={`${classes.popup}`}
        >
            <div className={classes.overlay} />
            <div className={`${classes.popupWithClose}`}>
                <Close onClick={() => setPopupProps({ open: false })} />
                <div onClick={(e) => e.stopPropagation()}>
                    <Hexagon popup={'popup'}>
                        <div className={classes.popupContent}>
                            <h5 className={classes.popupTitle}>
                                Enter canvas title
                            </h5>
                            <input
                                autoFocus
                                value={canvasTitle}
                                onChange={(e) => setCanvasTitle(e.target.value)}
                                placeholder="Canvas title"
                                className={classes.popupInput}
                            />
                            <button
                                className={classes.popupConfirm}
                                onClick={saveBoardHandler}
                            >
                                Confirm
                            </button>
                        </div>
                    </Hexagon>
                </div>
            </div>
        </div>
    ) : null
}
