import React, { useCallback, useContext, useEffect, useReducer } from 'react'
import BoardContext from './board-context'
import PropTypes from 'prop-types'
import AuthContext from '../auth/auth-context'
import { useTimeTravel } from '../../hooks/useTimeTravel'
import HexContext from '../hex/hex-context'
import PopupContext from '../../store/popup/popup-context'

const defaultBoardState = {
    shareData: {},
    shareDataLoading: false,
    shareDataError: null,
    sharePopupProps: false,
    addBoardPopupProps: {},
    versions: [],
    changed: false,
    saveChanged:false,
    autoSave: false,
    savePopupProps: {},
    deleteBoardPopupProps: {},
    saving: {
        status: false,
    },
    boardTitlePopupProps: {},
    zoomedHexId: null,
    operationCheck:false
}

const boardReducer = (state, action) => {
    switch (action.type) {
        case 'GET_SHARE_DATA_REQUEST':
            return {
                ...state,
                shareDataLoading: true,
            }
        case 'GET_SHARE_DATA_FAIL':
            return {
                ...state,
                shareDataLoading: false,
                shareDataError: 'Something went wrong !',
            }
        case 'GET_SHARE_DATA_SUCCESS':
            return {
                ...state,
                shareDataLoading: false,
                shareDataError: null,
                shareData: action.payload,
            }
        case 'SET_SHARE_POPUP_PROPS':
            return {
                ...state,
                sharePopupProps: action.payload,
            }
        case 'SET_ADD_BOARD_POPUP_PROPS':
            return {
                ...state,
                addBoardPopupProps: action.payload,
            }
        case 'SET_VERSIONS':
            return {
                ...state,
                versions: action.payload,
            }
        case 'SET_AUTOSAVE':
            return {
                ...state,
                autoSave: action.payload,
            }
        case 'SET_CHANGED':
            return {
                ...state,
                changed: action.payload,
            }
        case 'SET_SAVE_CHANGED':
            return {
                ...state,
                saveChanged: action.payload,
            }
        case 'SET_SAVE_POPUP_PROPS':
            return {
                ...state,
                savePopupProps: action.payload,
            }
        case 'SET_DELETE_BOARD_POPUP_PROPS':
            return {
                ...state,
                deleteBoardPopupProps: action.payload,
            }
        case 'SET_SAVING':
            return {
                ...state,
                saving: action.payload,
            }
        case 'SET_BOARD_TITLE_POPUP_PROPS':
            return {
                ...state,
                boardTitlePopupProps: action.payload,
            }
        case 'SET_ZOOMED_HEX_ID':
            return {
                ...state,
                zoomedHexId: action.payload,
            }
            case 'OPERATION_CHECK':
                return {
                    ...state,
                   operationCheck: action.payload,
                }
        case 'RESET':
            return defaultBoardState
        default:
            return state
    }
}

const hexagonX = 24
const hexagonY = 37
let hexagonList = []
let hexIndex = 0
for (let i = 1; i <= hexagonX; i++) {
    let p = 1
    for (let j = 0; j < hexagonY; j++) {
        const key = i + 'key' + j
        hexagonList = [
            ...hexagonList,
            {
                id: key,
                hexX: i,
                hexY: j,
                hexP: p,
                index: hexIndex,
                active: false,
                color: false,
                bold: false,
                italic: false,
                content: '',
                golden: false,
                init: false,
            },
        ]
        p += 2
        hexIndex++
    }
}

function reducer(state, action) {
    switch (action.type) {
        case 'SET':
            state.data = action.data
    }
}

const BoardProvider = (props) => {
    const authCtx = useContext(AuthContext)
    const hexCtx = useContext(HexContext)
    const popupCtx = useContext(PopupContext)

    const {
        // state,
        timeline,
        dispatch,
        doUndo: undoCount,
        doRedo: redoCount,
        doReset,
    } = useTimeTravel(reducer, { data: hexagonList })

    const [boardState, dispatcBoardAction] = useReducer(
        boardReducer,
        defaultBoardState
    )
    const setSharePopupProps = (payload) => {
        dispatcBoardAction({ type: 'SET_SHARE_POPUP_PROPS', payload })
    }
    const setAutoSave = (payload) => {
        dispatcBoardAction({ type: 'SET_AUTOSAVE', payload })
    }
    const setChanged = (payload) => {
        dispatcBoardAction({ type: 'SET_CHANGED', payload })
    }
    const setSaveChanged = (payload) => {
        dispatcBoardAction({ type: 'SET_SAVE_CHANGED', payload })
    }
    const setAddBoardPopupProps = (payload) => {
        dispatcBoardAction({ type: 'SET_ADD_BOARD_POPUP_PROPS', payload })
    }
    const setSavePopupProps = (payload) => {
        dispatcBoardAction({ type: 'SET_SAVE_POPUP_PROPS', payload })
    }
    const setDeleteBoardPopupProps = (payload) => {
        dispatcBoardAction({ type: 'SET_DELETE_BOARD_POPUP_PROPS', payload })
    }
    const setBoardTitlePopupProps = (payload) => {
        dispatcBoardAction({ type: 'SET_BOARD_TITLE_POPUP_PROPS', payload })
    }
    const setSaving = (payload) => {
        dispatcBoardAction({ type: 'SET_SAVING', payload })
    }
    const setZoomedHexId = (payload) => {
        dispatcBoardAction({ type: 'SET_ZOOMED_HEX_ID', payload })
    }
    const setOperationCheck = (payload) => {
        dispatcBoardAction({ type: 'OPERATION_CHECK', payload })
    }

    useEffect(()=>{
        if(  authCtx.isOnline == false && sessionStorage.getItem("onlineCheck") == null   ){
            setSavePopupProps({
                open: true,
                message: "Looks like you are offline. You can continue to make updates but the ‘Play’ features will be unavailable at this time. Your canvas will save when next online.",
            })
            sessionStorage.setItem( "onlineCheck", false);
                        
        }

    },[authCtx.isOnline])

    useEffect(() => {
        if (
            boardState.changed &&
            !hexCtx.playBackMode &&
            boardState.autoSave &&
            !boardState.boardTitlePopupProps.open&&
            sessionStorage.getItem("onlineCheck") == null 
        ) {
            saveHandler(boardState.versions)
        }
    }, [
        authCtx.userName,
        authCtx.boardName,
        hexCtx.changed,
        boardState.autoSave,
        hexCtx.playBackMode,
        boardState.versions,
        boardState.boardTitlePopupProps.open,
    ])

    const batchSaveHandler = (versions, popup, onSuccess) =>{
        dispatcBoardAction({ type: 'SET_SAVE_CHANGED', payload: true })
        const data = {
            boardId: authCtx.boardName,
            username: authCtx.userName,
            versions,
        }

        if( sessionStorage.getItem("onlineCheck") == null  ){
            setSaving({ status: true, message: 'Saving ...' })
            popup && setSavePopupProps({ open: true, message: 'Saving' })
        }
        fetch(process.env.REACT_APP_API_URL + '/user/dashboard/batch-save', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authCtx.token}`,
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                return response.json()
            })
            .then(async (response) => {
                dispatcBoardAction({ type: 'SET_SAVE_CHANGED', payload: false })
                if (response.status) {
                    authCtx.boardNameHandler(response.boardName)
                    authCtx.getBoardsHandler('save')
                    dispatcBoardAction({ type: 'SET_VERSIONS', payload: [] })
                    dispatcBoardAction({ type: 'SET_CHANGED', payload: false })
                    dispatcBoardAction({ type: 'SET_AUTOSAVE', payload: false })
                    // setSavingTextVisible(true)
                    setSavePopupProps({ open: false })
                    if (onSuccess) {
                        onSuccess()
                    }
                    setSaving({ status: true, message: 'Changes saved' })
                    setTimeout(() => {
                        setSaving({ status: false })
                    }, 2000)
                } else {
                    if( sessionStorage.getItem("onlineCheck") == null ){
                        setSavePopupProps({
                            open: true,
                            message: 'Something went wrong, please try again',
                        })
                    }                    
                    return;
                }
            })
            .catch(() => {
                dispatcBoardAction({ type: 'SET_SAVE_CHANGED', payload: false })     
                authCtx.setIsOnline(false);
                setSaving({ status: false, message: 'Something went wrong' })
            })
    }

    const copyHandler = (versions, popup) => {
        batchSaveHandler(versions, popup);
    }

    const saveHandler = (versions, popup, onSuccess) => {
        if (!versions.length) {
            dispatcBoardAction({ type: 'SET_CHANGED', payload: false })
            dispatcBoardAction({ type: 'SET_SAVE_CHANGED', payload: false })
            dispatcBoardAction({ type: 'SET_AUTOSAVE', payload: false })
            if (onSuccess) {
                onSuccess()
            }
            return
        }

        batchSaveHandler(versions, popup, onSuccess);        
    }
    const getShareData = () => {
        dispatcBoardAction({ type: 'GET_SHARE_DATA_REQUEST' })
        setSharePopupProps({
            ...boardState.sharePopupProps,
            boardTitle: authCtx.boardTitle,
            shareLink: null,
            message: 'Loading',
            shareImg: null,
        })
        const data = {
            accessType: false,
            boardName: authCtx.boardName,
            userRef: false,
        }
        fetch(process.env.REACT_APP_API_URL + '/user/dashboard/share', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authCtx.token}`,
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                if (response.status) {
                    const shareLink = `${window.location.origin}/?board=${response.shareId}`
                    dispatcBoardAction({
                        type: 'GET_SHARE_DATA_SUCCESS',
                        payload: {
                            ...response,
                            shareLink,
                        },
                    })
                    setSharePopupProps({
                        ...boardState.sharePopupProps,
                        boardTitle: authCtx.boardTitle,
                        shareLink,
                        message: `Share link: ${shareLink}`,
                        shareImg: authCtx.boardImage,
                    })
                }
            })
            .catch(() => {
                dispatcBoardAction({ type: 'GET_SHARE_DATA_FAIL' })
                setSharePopupProps({
                    ...boardState.sharePopupProps,
                    boardTitle: authCtx.boardTitle,
                    shareLink: null,
                    message: `Your canvas is currently blank. You are not able to share`,
                    shareImg: null,
                })
            })
    }
    const formatHexagons = (boardData, update, zoom = true) => {
        if (boardData?.canvas?.length) {
            let dataOrdered = [...boardData.canvas]
            let maxY = 0
            dataOrdered = dataOrdered.sort(function (a, b) {
                
                if (maxY < a.hexY) maxY = a.hexY

                if (maxY < b.hexY) maxY = b.hexY

                if (a.hexY == b.hexY) return a.hexY - b.hexY
                return a.hexX - b.hexX
            })
          
            let maxX = dataOrdered[dataOrdered.length-1].hexX
            let zoomId
            let midX = Math.ceil(maxX/2)
            let midY = Math.ceil(maxY/2)
            for(let i = 0; i < dataOrdered.length; i++){
                if(dataOrdered[i].hexX == midX && dataOrdered[i].hexY == midY){
                    zoomId = dataOrdered[i].index;
                }
            }
            let prevState = [...timeline.present.data]
            if (update) {
                prevState.forEach((item) => {
                    let newObject = { ...prevState[item.index] }
                    newObject.color = false
                    newObject.content = ''
                    newObject.bold = false
                    newObject.italic = false
                    newObject.init = false
                    newObject.golden = false
                    prevState[item.index] = newObject
                })
            }
            if (zoom) {
                let zoomItem = { ...prevState[zoomId] }
                zoomItem.init = true
                prevState[zoomId] = zoomItem
            }
            if (boardData) {
                boardData.canvas.forEach((item) => {
                    let newObject = { ...prevState[item.index] }
                    newObject.color = item.color
                    newObject.content = item.content
                    newObject.golden = item.golden
                    newObject.bold = item.bold
                    newObject.italic = item.italic
                    prevState[item.index] = newObject
                })
            }
            if (!update) {
                resetCount(prevState)
            } else {
                setHexagons({ data: prevState })
            }
        }
    }
    const operationHandler = useCallback(
        (operation) => {

            if(!authCtx.isOnline){
                popupCtx.setPopupProps({
                    open: true,
                    withClose: true,
                    message:
                        'Looks like you are offline. You can continue to make updates but the ‘Play’ features will be unavailable at this time. Your canvas will save when next online.',
                });

            }else{
            hexCtx.enableHexItem(false)
            setOperationCheck(true)
            setSavePopupProps({
                open: true,
                message: operation === 'sort' ? 'Sorting ...' : 'Shuffling ...',
            })
            const saveData = timeline.present.data
                .filter((item) => item.color !== false)
                .map(
                    ({
                        italic,
                        bold,
                        index,
                        id,
                        content,
                        color,
                        hexX,
                        hexY,
                        golden,
                    }) => ({
                        italic,
                        bold,
                        index,
                        id,
                        content,
                        color,
                        hexX,
                        hexY,
                        golden,
                    })
                )
            const objectData = { canvas: saveData }

            if (saveData.length === 0) {
                return
            }

            const data = {
                boardMessage: authCtx.boardMessage,
                json: objectData,
                userName: authCtx.userName,
                boardName: authCtx.boardName,
                operation: operation,
                title: authCtx.boardTitle,
                goldenNugget: false,
            }

            fetch(
                process.env.REACT_APP_API_URL + `/user/dashboard/${operation}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authCtx.token}`,
                    },
                    body: JSON.stringify(data),
                }
            )
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    if (response.status) {
                        setSavePopupProps({ open: false })
                        setChanged(true)
                        formatHexagons(response.data, true, true)
                        setOperationCheck(false)
                    } else {
                        setSavePopupProps({
                            open: true,
                            message: response.error,
                        })
                    }
                })
        }},
        [
            authCtx,
            authCtx.boardMessage,
            authCtx.boardTitle,
            timeline.present.data,
        ]      
    )

    const setHexagons = ({
        data,
        golden,
        boardName = authCtx.boardName,
        copy = false,
        forceSave = false,
        boardMessage = authCtx.boardMessage,
        undoRedoVersion = false,
    }) => {
        if(!undoRedoVersion)
            dispatch({ type: 'SET', data })
        const saveData = data
            .filter((item) => item.color !== false)
            .map(
                ({
                    italic,
                    bold,
                    index,
                    id,
                    content,
                    color,
                    hexX,
                    hexY,
                    golden,
                }) => ({
                    italic,
                    bold,
                    index,
                    id,
                    content,
                    color,
                    hexX,
                    hexY,
                    golden,
                })
            )
        const objectData = { canvas: saveData }
        const versionData = {
            boardMessage,
            json: objectData,
            userName: authCtx.userName,
            boardName: boardName,
            operation: false,
            title: authCtx.boardTitle,
            goldenNugget: golden,
        }
        dispatcBoardAction({
            type: 'SET_VERSIONS',
            payload: [...boardState.versions, versionData],
        })
        if (forceSave) {
            saveHandler([...boardState.versions, versionData], true)
        }

        if (copy) {
            const title = `COPY - ${versionData.title}`
            authCtx.boardTitleHandler(title)
            saveHandler(
                [
                    //...boardState.versions,
                    {
                        ...versionData,
                        title,
                    },
                ],
                true
            )
        }
        //closing message popup
        if(undoRedoVersion){          
            console.log("closing undo-redo loading popup at", new Date().getTime());  
            setSavePopupProps({open:false});            
        }
    }
    const resetCount = (data) => {
        doReset()
        dispatcBoardAction({ type: 'SET_VERSIONS', payload: [] })
        dispatch({ type: 'SET', data })
    }
    const reset = () => {
        doReset()
        dispatcBoardAction({ type: 'RESET' })
    }
    const boardContent = {
        ...boardState,
        timeline,
        undoCount,
        redoCount,
        doReset,
        setHexagons,
        resetCount,
        saveHandler,
        copyHandler,
        getShareData,
        setSharePopupProps,
        setAddBoardPopupProps,
        setChanged,
        setSaveChanged,
        setAutoSave,
        setSavePopupProps,
        setDeleteBoardPopupProps,
        operationHandler,
        setSaving,
        setBoardTitlePopupProps,
        setZoomedHexId,
        reset,
        setOperationCheck
    }

    return (
        <BoardContext.Provider value={boardContent}>
            {props.children}
        </BoardContext.Provider>
    )
}

BoardProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default BoardProvider