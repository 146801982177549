import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import classes from './BoardItem.module.css'
import AuthContext from '../../../../store/auth/auth-context'
import hexContext from '../../../../store/hex/hex-context'
import BoardContext from '../../../../store/board/board-context'
import PopupContext from '../../../../store/popup/popup-context'

const BoardItem = (props) => {
    const authCtx = useContext(AuthContext)
    const hexCtx = useContext(hexContext)
    const boardCtx = useContext(BoardContext)
    const popupCtx = useContext(PopupContext)

    const [currentTitle, setCurrentTitle] = useState(props.name)

    const boardHandler = () => {
        authCtx.boardTitleHandler('')
        const prevState = boardCtx.timeline.present.data.map((item) => ({
            ...item,
            color: false,
            content: '',
            bold: false,
            italic: false,
            init: false,
            golden: false,
        }))
        hexCtx.enableHexItem(false)
        boardCtx.setChanged(false)
        boardCtx.resetCount(prevState)
        authCtx.getBoardHandler(props.id)
        authCtx.boardNameHandler(props.id)
        authCtx.getBoardsHandler('save')
        hexCtx.disableModes()
        hexCtx.setBoardPopupProps({ active: false })
    }

    const deleteBoard = () => {
        boardCtx.setDeleteBoardPopupProps({ open: true, id: props.id })
    }

    const titleChangeHandler = (e) => {
        setCurrentTitle(e.target.value)
    }
    function getPreviewImage(url) {
        if (url && url.includes('.jpeg')) {
            return url
        } else {
            return require('../../../../assets/hex-example.png')
        }
    }
    return (
        <div
            className={`${classes.picker} ${
                classes[props.id === authCtx.boardName ? 'first' : 'last']
            }`}
        >
            <span
                onClick={() => {
                    if (boardCtx.changed) {
                        popupCtx.setPopupProps({
                            message:
                                'You have unsaved changes, would you like to continue without saving? Any unsaved changes will be lost',
                            open: true,
                            onOk: boardHandler,
                            onCancel: () =>
                                popupCtx.setPopupProps({ open: false }),
                        })
                    } else {
                        boardHandler()
                    }
                }}
                className={classes.button}
            >
                <img
                    draggable="false"
                    className={classes.image}
                    src={getPreviewImage(props.image)}
                />
            </span>
            <textarea
                draggable="false"
                rows={2}
                onChange={titleChangeHandler}
                onBlur={() => props.updateTitleHandler(props.id, currentTitle)}
                className={classes.tooltip}
                type="text"
                value={currentTitle}
            />
            <span onClick={deleteBoard} className={classes.delete}>
                x
            </span>
        </div>
    )
}

BoardItem.propTypes = {
    id: PropTypes.string,
    toggleBoard: PropTypes.func,
    image: PropTypes.string,
    name: PropTypes.string,
    updateTitleHandler: PropTypes.func,
}

export default BoardItem
