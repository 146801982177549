import React, { useContext, useEffect, useRef, useState } from 'react'

import classes from './Timeline.module.css'
import Point from './Point/Point'
import VersionForward from './VersionForward/VersionForward'
import VersionBack from './VersionBack/VersionBack'
import AuthContext from '../../../store/auth/auth-context'
import BoardContext from '../../../store/board/board-context'
import PropTypes from 'prop-types'

const Timeline = (props) => {
    const authCtx = useContext(AuthContext)
    const [points, setPoints] = useState([])
    const [currentVersion, setCurrentVersion] = useState(0)
    const [prevVersion, setPrevVersion] = useState()
    const [playInterval, setPlayInterval] = useState(0)
    const [activePlay, setActivePlay] = useState(false)
    const [activeBackPlay, setActiveBackPlay] = useState(false)
    const [versionsData, setVersionsData] = useState([])
    const [autoplay, setAutoplay] = useState()
    const playRef = useRef(null)
    const playBackRef = useRef(null)
    const boardCtx = useContext(BoardContext)
    const activateLoader = () => {
        boardCtx.setSavePopupProps({
            open: true,
            message: 'Loading...',
        });
    };

    const deactivateLoader = () => {
        setTimeout(() => {
            boardCtx.setSavePopupProps({
                open:false
            })
        }, 500)        
    };

    const backToCurrentVersionHandlerWithLoader = async () => {
        try {
            activateLoader();            
            await props.backToCurrentVersionHandler();
        } catch (error) {
            await props.backToCurrentVersionHandler();
        } finally {
            deactivateLoader();
        }
    };

    const [page, setPage] = useState(1)

    useEffect(() => {
        return () => {
            clearInterval(playInterval)
        }
    }, [playInterval])

    useEffect(() => {
        if (authCtx.versions.length) {
            const newPoints = authCtx.versions.map((point, index) => (
                <Point
                    key={point.versionID}
                    version={point.versionID}
                    position={index}
                    goldenGem={point.goldenNugget}
                    replaceHandler={props.replaceHandler}
                    versionHandler={versionHandler}
                    current={currentVersion === index}
                    preview={point.previewImageUrl}
                />
            ))
            setPoints(newPoints)
        }
    }, [authCtx.versions, props.replaceHandler])

    useEffect(() => {
        if (
            currentVersion === authCtx.versions.length - 1 ||
            currentVersion === 0
        ) {
            stopHandler()
        }
    }, [currentVersion])

    const getVersions = async (page) => {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user/dashboard/versions/${authCtx.boardName}/${page}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authCtx.token}`,
                },
            }
        )
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                return response
            })
        setVersionsData(response.versions[0])
        if (autoplay === 'forward') {
            playHandler()
        } else if (autoplay === 'back') {
            reversePlayHandler()
        }
    }

    useEffect(() => {
        authCtx.versions.length && authCtx.boardName && getVersions(page)
    }, [authCtx.versions, authCtx.boardName, page])
    const versionHandler = (index) => {
        setCurrentVersion(index)
    }

    useEffect(() => {
        versionReplace(currentVersion)
    }, [currentVersion, versionsData])

    const versionReplace = (index) => {
        const data = versionsData.find(
            (item) => item.id === authCtx.versions[index].versionID
        )
        if (data) {
            if (!prevVersion) {
                props.replaceHandler(data.json.canvas)
            } else {
                props.replaceHandler(data.json.canvas, prevVersion)
            }
            setPrevVersion(data.json.canvas)
            setCurrentVersion(index)
        } else {
            if (activePlay) {
                setAutoplay('forward')
            } else if (activeBackPlay) {
                setAutoplay('back')
            }
            stopHandler()
            const i = index + 1
            setPage(i > 10 ? Math.ceil(i / 10) : 1)
        }
    }

    const playHandler = () => {
        if (currentVersion === authCtx.versions.length - 1) {
            return
        }

        if (playInterval) {
            stopHandler()
        }

        const interval = setInterval(function () {
            playRef.current.click()
        }, 2500)

        setActivePlay(true)
        setPlayInterval(interval)
    }

    useEffect(() => {
        playHandler()
    }, [])

    const stopHandler = () => {
        clearInterval(playInterval)
        setPlayInterval(0)
        setActivePlay(false)
        setActiveBackPlay(false)
    }
    const reversePlayHandler = () => {
        if (currentVersion === 0) {
            return
        }
        if (playInterval) {
            stopHandler()
        }

        const interval = setInterval(function () {
            playBackRef.current.click()
        }, 2500)
        setActiveBackPlay(true)
        setPlayInterval(interval)
    }

    return (
        <div className={classes.timeline}>
            <div className={classes.exitBtn}>
                <img
                   draggable="false"
                    src={require('../../../assets/exit.png')}
                    onClick={backToCurrentVersionHandlerWithLoader}
                />
                Exit Replay
            </div>
            <div className={classes.timelineNav}>
                <VersionBack
                    ref={playBackRef}
                    versionHandler={versionHandler}
                    currentVersion={currentVersion}
                    versionsMax={authCtx.versions.length - 1}
                />
                <VersionForward
                    ref={playRef}
                    versionHandler={versionHandler}
                    currentVersion={currentVersion}
                    versionsMax={authCtx.versions.length - 1}
                />
                <div className={classes.start} onClick={playHandler} id="play">
                    <img
                        draggable="false"
                        className={`${classes.image} ${
                            activePlay && classes.active
                            }`}
                        src={require('../../../assets/Playback_Forward.png')}
                    />
                </div>
                <div className={classes.start} onClick={stopHandler} id="pause">
                    <img
                        draggable="false"
                        className={`${classes.image} ${
                            !activePlay && !activeBackPlay && classes.active
                            }`}
                        src={require('../../../assets/Playback_stop.png')}
                    />
                </div>
                <div
                    className={classes.stop}
                    onClick={reversePlayHandler}
                    id="rewind"
                >
                    <img
                        draggable="false"
                        className={`${classes.image} ${
                            activeBackPlay && classes.active
                            }`}
                        src={require('../../../assets/Playback_Rewind.png')}
                    />
                </div>
            </div>

            <div className={classes.pointsWrapper}>
                <img
                    draggable="false"
                    className={`${classes.line} `}
                    src={require('../../../assets/Playback_Scrubbar.png')}
                />
                {points}
            </div>
        </div>
    )
}

Timeline.propTypes = {
    replaceHandler: PropTypes.func,
    backToCurrentVersionHandler: PropTypes.func,
}

export default Timeline
