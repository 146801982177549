import React, { useContext, useEffect, useState } from 'react'
import AuthContext from '../../store/auth/auth-context'
import BoardContext from '../../store/board/board-context'
import HexContext from '../../store/hex/hex-context'
import Close from '../Home/Close/Close'
import Hexagon from '../Login/Hexagon/Hexagon'
import classes from './AddBoardPopup.module.css'

export default function AddBoardPopup() {
    const authCtx = useContext(AuthContext)
    const hexCtx = useContext(HexContext)
    const boardCtx = useContext(BoardContext)

    const [canvasTitle, setCanvasTitle] = useState('')
    const popupProps = boardCtx.addBoardPopupProps
    const setPopupProps = boardCtx.setAddBoardPopupProps

    const addBoardHandler = () => {
        hexCtx.enableHexItem(false)
        hexCtx.disableModes()
        authCtx.boardTitleHandler('')
        const prevState = boardCtx.timeline.present.data.map((item) => ({
            ...item,
            color: false,
            content: '',
            bold: false,
            italic: false,
            init: false,
            golden: false,
        }))
        boardCtx.resetCount(prevState)
        authCtx.boardNameHandler(false)
        authCtx.boardMessageHandler(false)
        hexCtx.addHexModeHandler(!boardCtx.addHexMode)
        canvasTitle.length && authCtx.boardTitleHandler(canvasTitle)
        setPopupProps({ open: false })
        boardCtx.saveHandler(
            [
                {
                    boardMessage: false,
                    json: {
                        canvas: [],
                    },
                    userName: authCtx.userName,
                    boardName: false,
                    operation: false,
                    title: canvasTitle,
                },
            ],
            true,
            () => {
                sessionStorage.setItem('isNewUser', false)
                authCtx.setIsNewUser(false)
                hexCtx.setBoardPopupProps({ open: false })
            }
        )
    }
    useEffect(() => {
        setCanvasTitle('')
    }, [popupProps.open])
    return popupProps.open ? (
        <div
            onClick={() => setPopupProps({ open: false })}
            className={`${classes.popup}`}
        >
            <div className={classes.overlay} />
            <div className={`${classes.popupWithClose}`}>
                <Close onClick={() => setPopupProps({ open: false })} />
                <div onClick={(e) => e.stopPropagation()}>
                    <Hexagon popup={'popup'}>
                        <div className={classes.popupContent}>
                            <h5 className={classes.popupTitle}>
                                Add New Canvas
                            </h5>
                            <input
                                autoFocus
                                value={canvasTitle}
                                onChange={(e) => setCanvasTitle(e.target.value)}
                                placeholder="Canvas title"
                                className={classes.popupInput}
                            />
                            <button
                                className={classes.popupConfirm}
                                onClick={addBoardHandler}
                            >
                                Confirm
                            </button>
                        </div>
                    </Hexagon>
                </div>
            </div>
        </div>
    ) : null
}
