import React, { useState, useContext, useEffect, useRef } from 'react'
import classes from './Point.module.css'
import PropTypes from 'prop-types'
import AuthContext from '../../../../store/auth/auth-context'

const Point = (props) => {
    const [showPreview, setShowPreview] = useState(false)
    const authCtx = useContext(AuthContext)
    const itemRef = useRef(null)
    const changeVersion = () => {
        props.versionHandler(props.position)
    }

    const previewInHandler = () => {
        setShowPreview(true)
    }

    const previewOutHandler = () => {
        setShowPreview(false)
    }

    useEffect(() => {
        if (props.current) {
            itemRef.current.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
            })
        }
    }, [props.current])

    return (
        <div
            ref={itemRef}
            onClick={changeVersion}
            className={classes.pointWrapper}
            onMouseEnter={previewInHandler}
            onMouseLeave={previewOutHandler}
        >
            <div
                style={{ zIndex: !props.current ? props.position + 1 : 0 }}
                className={`${classes.point}  ${
                    classes[props.goldenGem ? 'golden-point' : '']
                }`}
            >
                {/*{props.goldenGem && <img src={props.preview} alt=""/>}*/}
                <div
                    className={`${classes.pointItem}  ${
                        classes[
                            props.current && props.position >= 1 && 'active'
                        ]
                    } ${
                        classes[
                            props.current &&
                                props.position ===
                                    authCtx.versions.length - 1 &&
                                'last'
                        ]
                    }`}
                ></div>
                <img
                    className={`${classes.preview} ${
                        classes[showPreview && 'show']
                    }`}
                    src={props.preview}
                    alt=""
                />
            </div>
        </div>
    )
}

Point.propTypes = {
    versionHandler: PropTypes.func,
    position: PropTypes.number,
    goldenGem: PropTypes.bool,
    current: PropTypes.bool,
    preview: PropTypes.string,
}

export default Point
