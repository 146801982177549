import React, { useContext, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import AdminContext from '../../store/admin/admin-context'
import rrwebPlayer from 'rrweb-player'
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import Select from 'react-select'
import classes from './Admin.module.css'

const Admin = () => {
    const ctx = useContext(AdminContext)
    const [filters, setFilters] = useState({})
    const [pageData, setPageData] = useState([])
    const [videoPopupActive, setVideoPopupActive] = useState(false)
    const [usersList, setUsersList] = useState([])
    const [searchValue, setSearchValue] = useState('')

    let replayer
    useEffect(() => {
        ctx.getActivities()
    }, [])
    useEffect(() => {
        if (ctx.activityData.activites?.length) {
            setPageData(ctx.activityData.activites[0])
        } else {
            setPageData([])
        }
    }, [ctx.activityData.activites])
    useEffect(() => {
        if (ctx.users) {
            setUsersList(ctx.users)
        }
    }, [ctx.users])

    const startDateHandler = (createdDate) => {
        setFilters((prev) => ({ ...prev, createdDate }))
    }

    const endDateHandler = (endDate) => {
        setFilters((prev) => ({ ...prev, endDate }))
    }
    const searchUser = (value) => {
        if (usersList.length && value.includes(searchValue)) {
            setUsersList((prev) => prev.filter((item) => item.includes(value)))
        } else if (value.length >= 3) {
            ctx.getUsers(value)
        }
        setSearchValue(value)
    }
    const usernameHandler = (username) => {
        setFilters((prev) => ({ ...prev, username: username.value }))
    }

    const selectHandler = (id, batchId = null) => {
        ctx.getActivityContent(id, batchId, () => setVideoPopupActive(true))
    }
    const renderPlayer = (events) => {
        replayer = new rrwebPlayer({
            target: document.getElementById('tracking_video'),
            props: {
                events: events,
                width: 800,
                height: 600,
                autoPlay: false,
                skipInactive: true,
            },
        })
        replayer.play()
        replayer.addEventListener('finish', () => {
            const nextBtn = document.getElementById('rrweb-play-next')
            nextBtn?.click()
        })
    }
    const handleNextPage = () => {
        if (
            Number(ctx.activityData.currentPage) <
            Number(ctx.activityData.totalPage)
        ) {
            ctx.getActivities({
                ...filters,
                page: +ctx.activityData.currentPage + 1,
            })
        }
    }

    const handlePrevPage = () => {
        if (ctx.activityData.currentPage > 1) {
            ctx.getActivities({
                ...filters,
                page: +ctx.activityData.currentPage - 1,
            })
        }
    }

    const handleNext = () => {
        replayer && replayer.$destroy()
        const activityId = ctx.activityContent?.activityId
        const nextBatchId = ctx.activityContent?.nextBatchId
        if (activityId && nextBatchId) {
            selectHandler(activityId, nextBatchId)
        }
    }
    const pageHandler = () => {
        const page = filters.page
        if (
            page !== ctx.activityData?.currentPage &&
            page <= ctx.activityData?.totalPage &&
            page >= 1
        ) {
            ctx.getActivities({ ...filters, page })
        }
    }

    useEffect(() => {
        if (
            videoPopupActive &&
            ctx.activityContent?.content.events?.length >= 2
        ) {
            renderPlayer(ctx.activityContent.content.events)
        }
    }, [videoPopupActive, ctx.activityContent])

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0')
    }
    function convertMsToTime(milliseconds) {
        let seconds = Math.floor(milliseconds / 1000)
        let minutes = Math.floor(seconds / 60)
        let hours = Math.floor(minutes / 60)

        seconds = seconds % 60
        minutes = minutes % 60
        hours = hours % 24

        return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
            seconds
        )}`
    }
    const hexagonReportHandler = () => { 
        fetch(`${process.env.REACT_APP_API_URL}/api/report/hexagon`,{
            method: "POST",
            headers: {
                Authorization: `Bearer ${ctx.token}`,
            },
        })
            .then(resp => resp.blob())
            .then(blob => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              // the filename you want
              a.download = 'hexagonReport.csv';
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);     
            })
    }

    const hexagonCountReportHandler = () => {
        fetch(`${process.env.REACT_APP_API_URL}/api/report/hexagon-count`,{
            method: "GET",
            headers: {
                Authorization: `Bearer ${ctx.token}`,
            },
        })
        .then(resp => resp.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          a.download = 'hexagonCountReport.csv';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
      
        })
    }

    


    return (
        <main className={classes.main}>
            {videoPopupActive && (
                <div
                    className={classes.videoPopup}
                    onClick={() => setVideoPopupActive(false)}
                >
                    <div
                        id="tracking_video"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            className={classes.close}
                            onClick={() => setVideoPopupActive(false)}
                        >
                            X
                        </button>
                        {ctx.activityContent?.nextBatchId?.length ? (
                            <button
                                onClick={handleNext}
                                className={classes.next}
                                id="rrweb-play-next"
                            >
                                Next
                            </button>
                        ) : null}
                    </div>
                </div>
            )}
            {ctx.activityDataLoading && (
                <div className={classes.loaderPopup}>
                    <div>Loading...</div>
                </div>
            )}
            <section className={classes.activitySection}>
                <div className={classes.heading}>
                    <Select
                        styles={{
                            control: (baseStyles) => ({
                                ...baseStyles,
                                border: '1px solid hsl(0, 0%, 80%)',
                            }),
                        }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: 'hsl(0, 0%, 80%)',
                            },
                        })}
                        onInputChange={searchUser}
                        className={classes.usernameInput}
                        options={usersList.map((item) => ({
                            value: item,
                            label: item,
                        }))}
                        onChange={usernameHandler}
                    />
                    <DatePicker
                        wrapperClassName={[
                            classes.datePicker,
                            classes.startDate,
                        ]}
                        placeholderText="start date"
                        selected={filters.createdDate}
                        onChange={(date) => startDateHandler(date)}
                        dateFormat="yyyy-MM-dd"
                    />
                    <DatePicker
                        wrapperClassName={[classes.datePicker, classes.endDate]}
                        placeholderText="end date"
                        selected={filters.endDate}
                        onChange={(date) => endDateHandler(date)}
                        dateFormat="yyyy-MM-dd"
                    />
                    <button onClick={() => ctx.getActivities(filters)}>
                        Filter
                    </button>
                </div>
                <div className={classes.content}>
                    <div className={classes.row}>
                        <span className={classes.username}>
                            <b>Username</b>
                        </span>
                        <span className={classes.date}>
                            <b>Date</b>
                        </span>
                        <span className={classes.time}>
                            <b>Time</b>
                        </span>
                        <span className={classes.duration}>
                            <b>Duration</b>
                        </span>
                    </div>
                    {pageData.map((item) => (
                        <div
                            onClick={() => selectHandler(item.id)}
                            className={classes.row}
                            key={item.id}
                        >
                            <span className={classes.username}>
                                {item.username}
                            </span>
                            <span className={classes.date}>
                                {dayjs(new Date(item.createdDate)).format(
                                    'YYYY-MM-DD'
                                )}
                            </span>
                            <span className={classes.time}>
                                {dayjs(new Date(item.createdDate)).format(
                                    'hh:mm'
                                )}
                            </span>
                            <span className={classes.duration}>
                                {convertMsToTime(item.duration)}
                            </span>
                        </div>
                    ))}
                    {ctx.activityDataError}
                </div>
                <div className={classes.pagination}>
                    <div>
                        Page: {ctx.activityData?.currentPage || ''} of{' '}
                        {ctx.activityData?.totalPage || ''}
                    </div>
                    <div>
                        <button onClick={handlePrevPage}>{'<'}</button>
                        <button onClick={handleNextPage}>{'>'}</button>
                        <input
                            type="text"
                            value={filters.page || ''}
                            onChange={(e) =>
                                setFilters((prev) => ({
                                    ...prev,
                                    page: e.target.value,
                                }))
                            }
                        />
                        <button onClick={pageHandler}>GO</button>
                    </div>
                </div>
              
                <div className={classes.hexagonReport}>
                <p className={classes.reports}>Reports</p>
                    <button className={classes.hexagonReportBtn} onClick={hexagonReportHandler} >All Hexagons</button>
                    <button  className={classes.hexagonCountReportBtn}onClick={hexagonCountReportHandler} >Canvas Hexa Count </button>
                </div>             
            </section>
        </main>
    )
}

export default Admin
