import React, { useContext, useEffect } from 'react'
import classes from './SharePopup.module.css'
import BoardContext from '../../store/board/board-context'
import Close from '../Home/Close/Close'
import useViewPort from '../../hooks/useViewPort'

export default function SharePopup() {
    const boardCtx = useContext(BoardContext)
    const popupProps = boardCtx.sharePopupProps
    const [mobile] = useViewPort()
    useEffect(() => {
        if (popupProps.open) {
            boardCtx.getShareData()
        }
    }, [popupProps.open])

    return boardCtx.sharePopupProps.open ? (
        <div
            className={classes.popup}
            onClick={() => boardCtx.setSharePopupProps({ open: false })}
        >
            <div className={classes.overlay} />
            <div className={classes.popupWithClose}>
                <Close
                    onClick={() => boardCtx.setSharePopupProps({ open: false })}
                />
                <div
                    className={classes.content}
                    onClick={(e) => e.stopPropagation()}
                >
                    <span className={classes.title}>
                        {popupProps.boardTitle}
                    </span>
                    <span>
                        Sharing this link will allow others to view the content
                        of this canvas as well as any future updates you make.
                    </span>
                    {!mobile && (
                        <div className={classes.capture}>
                            <img src={popupProps.shareImg} />
                        </div>
                    )}
                    <span className={classes.message}>
                        {popupProps.message}
                    </span>
                    <button
                        className={classes.modalBtn}
                        onClick={() => {
                            navigator.clipboard.writeText(popupProps.shareLink)
                            boardCtx.setSharePopupProps({ open: false })
                        }}
                    >
                        Copy
                    </button>
                </div>
            </div>
        </div>
    ) : null
}
