import React, { useReducer } from 'react'
import HexContext from './hex-context'
import PropTypes from 'prop-types'

const defaultHexState = {
    selectMode: false,
    copyMode: false,
    trashMode: false,
    activeHex: false,
    addHexMode: false,
    colorPicker: false,
    playBackMode: false,
    showTimer: false,
    changed: false,
    selectData: [],
    copyData: {},
    selectedHexagons: [],
    boardPopupProps: {},
    focusedHex: null,
}

const hexReducer = (state, action) => {
    switch (action.type) {
        case 'SELECT':
            return {
                ...state,
                selectMode: !state.selectMode,
                activeHex: !state.selectMode ? null : state.activeHex,
                selectedHexagons: [],
                copyMode: false,
                addHexMode: false,
                colorPicker: false,
            }
        case 'COPY':
            return {
                ...state,
                selectMode: state.selectMode,
                copyMode: !state.copyMode,
                addHexMode: false,
                colorPicker: false,
            }
        case 'ADD_MODE':
            return {
                ...state,
                selectMode: false,
                copyMode: false,
                addHexMode: action.status,
                colorPicker: false,
            }
        case 'COLOR_PICKER':
            return {
                ...state,
                copyMode: false,
                addHexMode: false,
                showTimer: false,
                colorPicker: action.status,
            }
        case 'TIMER':
            return {
                ...state,
                copyMode: false,
                addHexMode: false,
                colorPicker: false,
                showTimer: action.status,
            }
        case 'DISABLE':
            return {
                ...state,
                colorPicker: false,
                addHexMode: false,
                playBackMode: false,
                showTimer: false,
                copyMode: false,
                selectMode: false,
            }
        case 'PLAYBACK':
            return {
                ...defaultHexState,
                playBackMode: action.status,
            }
        case 'COPY_DATA':
            return {
                ...state,
                copyData: action.data,
                zoomHex: '',
            }
        case 'ACTIVE_HEX':
            return {
                ...state,
                activeHex: action.item,
                zoomHex: '',
            }
        case 'ZOOM_HEX':
            return {
                ...state,
                zoomHex: action.item,
            }
        case 'SELECT_DATA': {
            const selectedData = [action.data, ...state.selectData]
            return {
                ...state,
                selectData: selectedData,
            }
        }
        case 'SET_SELECTED': {
            return {
                ...state,
                selectedHexagons: [...state.selectedHexagons, action.data],
            }
        }
        case 'REMOVE_SELECTED': {
            return {
                ...state,
                selectedHexagons: state.selectedHexagons.filter(
                    (item) => item !== action.data
                ),
            }
        }
        case 'CLEAR_SELECTED': {
            return {
                ...state,
                selectedHexagons: [],
            }
        }
        case 'SET_CHANGED': {
            return {
                ...state,
                changed: action.data,
            }
        }
        case 'SET_BOARD_POPUP_PROPS': {
            return {
                ...state,
                boardPopupProps: action.data,
            }
        }
        case 'SET_FOCUSED_HEX': {
            return {
                ...state,
                focusedHex: action.data,
            }
        }
        case 'RESET': {
            return defaultHexState
        }
        default:
            return state
    }
}

const HexProvider = (props) => {
    const [hexState, dispatcHexAction] = useReducer(hexReducer, defaultHexState)

    const enableSelectMode = () => {
            dispatcHexAction({ type: 'SELECT' })
        },
        enableZoom = (zoomId) => {
            dispatcHexAction({ type: 'ZOOM_HEX', item: zoomId })
        },
        disableModes = () => {
            dispatcHexAction({ type: 'DISABLE' })
        },
        enableCopyMode = () => {
            dispatcHexAction({ type: 'COPY' })
        },
        enableHexItem = (id) => {
            dispatcHexAction({ type: 'ACTIVE_HEX', item: id })
        },
        setCopyData = (data) => {
            dispatcHexAction({ type: 'COPY_DATA', data: data })
        },
        addHexModeHandler = (value) => {
            const status = value ? value : false
            dispatcHexAction({ type: 'ADD_MODE', status: status })
        },
        colorPickerHandler = (status) => {
            dispatcHexAction({ type: 'COLOR_PICKER', status: status })
        },
        enablePlayBackMode = () => {
            dispatcHexAction({ type: 'PLAYBACK', status: true })
        },
        disablePlayBackMode = () => {
            dispatcHexAction({ type: 'PLAYBACK', status: false })
        },
        showTimerHandler = (value) => {
            const status = value ? value : false
            dispatcHexAction({ type: 'TIMER', status: status })
        },
        setSelectData = (index, position, content) => {
            const data = { id: index, position: position, content: content }
            dispatcHexAction({ type: 'SELECT_DATA', data: data })
        },
        setSelected = (index) => {
            dispatcHexAction({ type: 'SET_SELECTED', data: index })
        },
        removeSelected = (index) => {
            dispatcHexAction({ type: 'REMOVE_SELECTED', data: index })
        },
        clearSelected = () => {
            dispatcHexAction({ type: 'CLEAR_SELECTED' })
        },
        setChanged = (data) => {
            dispatcHexAction({ type: 'SET_CHANGED', data })
        },
        setBoardPopupProps = (data) => {
            dispatcHexAction({ type: 'SET_BOARD_POPUP_PROPS', data })
        },
        setFocusedHex = (data) => {
            dispatcHexAction({ type: 'SET_FOCUSED_HEX', data })
        },
        reset = () => {
            dispatcHexAction({ type: 'RESET' })
        }
    const hexContent = {
        selectMode: hexState.selectMode,
        copyMode: hexState.copyMode,
        copyData: hexState.copyData,
        colorPicker: hexState.colorPicker,
        activeHex: hexState.activeHex,
        zoomHex: hexState.zoomHex,
        addHexMode: hexState.addHexMode,
        selectData: hexState.selectData,
        playBackMode: hexState.playBackMode,
        showTimer: hexState.showTimer,
        selectedHexagons: hexState.selectedHexagons,
        changed: hexState.changed,
        boardPopupProps: hexState.boardPopupProps,
        focusedHex: hexState.focusedHex,
        setBoardPopupProps,
        addHexModeHandler,
        enableHexItem,
        enableZoom,
        enableSelectMode,
        disableModes,
        enableCopyMode,
        setCopyData,
        setSelectData,
        colorPickerHandler,
        enablePlayBackMode,
        disablePlayBackMode,
        showTimerHandler,
        setSelected,
        removeSelected,
        clearSelected,
        setChanged,
        setFocusedHex,
        reset,
    }

    return (
        <HexContext.Provider value={hexContent}>
            {props.children}
        </HexContext.Provider>
    )
}

HexProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default HexProvider
